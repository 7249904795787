import React, {useState} from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AdbIcon from '@mui/icons-material/Adb';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import GppMaybeRoundedIcon from '@mui/icons-material/GppMaybeRounded';
import ThunderstormRoundedIcon from '@mui/icons-material/ThunderstormRounded';
import SportsMartialArtsRoundedIcon from '@mui/icons-material/SportsMartialArtsRounded';
import Filter2RoundedIcon from '@mui/icons-material/Filter2Rounded';
import SickRoundedIcon from '@mui/icons-material/SickRounded';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import DomainVerificationRoundedIcon from '@mui/icons-material/DomainVerificationRounded';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';


import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';

function preventDefault(event) {
  event.preventDefault();
}

export default function DataBoxWeather(props) {
  const[scheduledTime, setScheduledTime] = useState(props.danielJson?.airLawData?.departure?.scheduledTime);
  const[estimatedTime, setEstimatedTime] = useState(props.danielJson?.airLawData?.departure?.estimatedTime);
  const[iataCode, setIataCode] = useState(props.danielJson?.airLawData?.departure?.iataCode);
  const[icaoCode, setIcaoCode] = useState(props.danielJson?.airLawData?.departure?.icaoCode);
  const[wundergroundLink, setWundergroundLind] = useState("https://www.wunderground.com/history/daily/"+props.danielJson?.airLawData?.departure?.codeIso2Country+"/"+props.danielJson?.airLawData?.departure?.nameCity+"/"+icaoCode+"/date/"+scheduledTime.toString("YYYY-MM-dd"));  
  const[delay, setDelay] = useState(props.danielJson?.airLawData?.departure?.delay || 0);
  const[delayHours, setDelayHours] = useState(Math.floor(delay/60) || 0); // => 4 => the times 3 fits into 13  
  const[delayMin, setDelayMin] = useState((delay % 60) || 0); // => 4 => the times 3 fits into 13    
  
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onClickUrl = (url) => {
    return () => openInNewTab(url)
  }

  return (
    <React.Fragment>      
     <h1>Departure airport (iata: {iataCode.toUpperCase()} icao: {icaoCode.toUpperCase()})</h1>
     <Grid container spacing={2} height='180px'>
        <Grid item xs={12} md={3} container alignItems="center" justifyContent="center"> 
        {(props.danielJson?.airLawData?.flightEvaluationEngine?.notamsAlert) && (props.danielJson?.airLawData?.departure?.notams != null) && <GppMaybeRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />}
        {(props.danielJson?.airLawData?.flightEvaluationEngine?.covidFlightAlert) && (props.danielJson?.airLawData?.departure?.covidAlert != false) && <SickRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />}
        {(props.danielJson?.airLawData?.flightEvaluationEngine?.airportStrikeAlert) && (props.danielJson?.airLawData?.departure?.strike != null) && <SportsMartialArtsRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />}
        {(props.danielJson?.airLawData?.flightEvaluationEngine?.explicitlyGoodWeatherTag) && <WbSunnyRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />}
        {(props.danielJson?.airLawData?.flightEvaluationEngine?.seemsBadWeatherTag) && <ThunderstormRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />}
        {(props.danielJson?.dataSourcesProblemDetected) && (props.danielJson?.airLawData?.departure?.delay != null) &&((-25 < (180 - delay)) && (((180 - delay) <= 25)) ) && <BrowseGalleryIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />}
        {(props.danielJson?.airLawData?.departure.notams == null) &&  (props.danielJson?.airLawData?.departure?.covidAlert == false) && (props.danielJson?.airLawData?.departure?.strike == null) && ((props.danielJson?.airLawData?.flightEvaluationEngine?.explicitlyGoodWeatherTag == false) || (props.danielJson.airLawData.flightEvaluationEngine.explicitlyGoodWeatherTag == null) || (props.danielJson.airLawData.flightEvaluationEngine.seemsBadWeatherTag == false)|| (props.danielJson.airLawData.flightEvaluationEngine.seemsBadWeatherTag == null)) && <DomainVerificationRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(25, 118, 210, 1.0)" }} />}
        {/* {(!props.danielJson.airLawData.flightEvaluationEngine.explicitlyGoodWeatherTag) && <WbSunnyRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 50, mr: 2,color: "rgba(255, 255, 0, 0.9)" }} />}        */}
       
        </Grid>
        <Grid item xs={12} md={9}>          
          <Typography variant="h4">
            {props.danielJson?.airLawData?.departure?.nameCity } ( { props.danielJson?.airLawData?.departure?.codeIso2Country } )
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            {props.danielJson?.airLawData?.departure?.nameAirport} 
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
          Schedule: {scheduledTime}
          </Typography>    
          <Typography color="text.secondary" sx={{ flex: 1 }}>
          {estimatedTime != null ? ('Esteam: ' + estimatedTime) : ""}            
          </Typography>  
          <Typography color="text.secondary" sx={{ flex: 1 }}>          
          {(props.danielJson?.airLawData?.departure?.delay != null) && ((delay >0)) ? 'Delay: '+ delayHours+':'+ (((0<delay)&& (delayMin<10))?'0':'') + delayMin +' ('+ delay + ' min)' : delay + ' min'}                                           
          </Typography>            
      
        </Grid>
      </Grid> 
      <Grid container spacing={1} padding={3}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} container alignItems="center" justifyContent="center">                          
              <Button variant="outlined" onClick={onClickUrl(wundergroundLink)}  sx={{ width: { md: '250px', xs: '100%' },  }} >WEATHER WUNDER:</Button>
          </Grid>
          <Grid item xs={9} md={9}>
          {(props.danielJson.departureAirportWeatherHistory?.summary != null) &&  props.danielJson.departureAirportWeatherHistory.summary}
           {/* Dewpoint:  {props.danielJson.departureAirportWeatherHistory.results[0].dewpoint.repr}°C  Altimeter: {props.danielJson.departureAirportWeatherHistory.results[0].altimeter.repr} */}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} container alignItems="center" justifyContent="center">
          <Button variant="outlined" onClick={onClickUrl("http://www.flightutilities.com/MRonline.aspx")}  sx={{ width: { md: '250px', xs: '100%' },  }}>METAR DECODER:</Button>
          </Grid>
          <Grid item xs={9} md={9}>
          {(props.danielJson.departureAirportWeatherHistory?.nearestRaw != null) &&  props.danielJson?.departureAirportWeatherHistory?.nearestRaw}
          {(props.danielJson.departureAirportWeatherHistory?.results != null) &&  props.danielJson?.departureAirportWeatherHistory?.results[0].raw }
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} container alignItems="center" justifyContent="center">
            <Button variant="outlined" sx={{ width: { md: '250px', xs: '100%' },  }} >NOTAMS:</Button>
          </Grid>
          <Grid item xs={9} md={9}>
              No critical NOTAMS detected
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} container alignItems="center" justifyContent="center">
            <Button variant="outlined" sx={{ width: { md: '250px', xs: '100%' },  }}>AIRPORT STRIKES:</Button>
          </Grid>
          <Grid item xs={9} md={9}>
            {(props.danielJson?.airLawData.departure?.covidAlert == null) && <>N/A</>} 
            {(props.danielJson?.airLawData.departure?.covidAlert == false) && <>No strike detected</>} 
            
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

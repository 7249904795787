import React, { useEffect, useState } from 'react';
import {supabase} from '../lib/supabase';
import { useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from '../components/authContextProvider/AuthContext';
import ModalImage from "react-modal-image";

import { experimentalStyled as styled } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Login() {
  
  const [logoUrl, setLogoUrl] = useState('');
  const [contactToGetAnAccount, setContactToGetAnAccount] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  //const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("sessionEmanuel"))?.user || null);
  const { user } = useUser();

  const navigate = useNavigate(); 
  const handlRedirectFlightSearch = () => {       
    navigate('/flightsearch');
  };

  const handlRedirectSmartTemplate = () => {       
    navigate('/templates');
  };
  const handleRedirectTemplates = () => {       
    navigate('/templates');
  };
  const handleRedirectCurrency = () => {       
    navigate('/currencyconvert');
  };
 
  

   useEffect(() => {
      setContactToGetAnAccount(process.env.REACT_APP_CONTACT_LOGIN);
      setLogoUrl(process.env.REACT_APP_LOGO_URL);
  }, []);

  //  React.useEffect(() => {
  //     setContactToGetAnAccount(process.env.REACT_APP_CONTACT_LOGIN);
  //     setLogoUrl(process.env.REACT_APP_LOGO_URL);
  //     console.log('SECONDARY LISTENER triggered');
  //     if(sessionEmanuel!= null)
  //     {
  //       console.log('SECONDARY LISTENER setting Session');
  //     }
  // }, [sessionEmanuel]);

  // React.useEffect(() => {
  //   const { data: authListener } = supabase.auth.onAuthStateChange(
  //     (event, session) => {
  //       switch (event) {
  //         case "SIGNED_IN":
  //           console.log('SIGN_IN EVENT setting Session to variable sessionEmanuel');
  //           console.log(session);
  //           setSessionEmanuel(session);
  //           sessionStorage.setItem("sessionEmanuel", JSON.stringify(session));
  //           setUser(session?.user);
  //           break;
  //         case "SIGNED_OUT":
  //           setSessionEmanuel(null);
  //           setUser(null);
  //           sessionStorage.removeItem("sessionEmanuel");
  //           navigate('/login');
  //           break;
  //         default:
  //       }
  //     }
  //   );
  //   return () => {
  //     authListener.subscription.unsubscribe();
  //   };
  // }, []);
  async function logActivity (userData, activity)
  {
    console.log(userData);
    //const correlationID = uuid();
    
    const { data, error } = await supabase
      .from('logUserActivity')
      .insert([
        { user_email: userData.email, user_id: userData.id, type: activity, correlationID: null },
      ])
  }

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://paxhelper.com">
          paxhelper.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
   }

   const theme = createTheme();

   async function logout(){   
          const myPromise = await logActivity(user, 'logout');      
          localStorage.removeItem('emanuelUser');          
          const { error } = await supabase.auth.signOut();
          // setUser(null);
   }
   
    async function handleSubmit (event) {
      try{
        event.preventDefault();
        const form_data = new FormData(event.currentTarget);
        const { data, error } = await supabase.auth.signInWithPassword({
          email: form_data.get('email'),
          password: form_data.get('password'),
        })
        if (error) throw error;
        console.log(data);
        logActivity(data.user, 'login');

        const { data: d2, error :e2 } = await supabase.from('emanuelUsers').select('*').eq('email', form_data.get('email')).single();
        if (e2) throw e2;
        //const emanuelUser = data2[0];
        console.log(d2);      
        localStorage.setItem('emanuelUser', JSON.stringify(d2) );

        //localStorage.setItem('emanuelUser', emanuelUser);
        navigate('/flightsearch');  
      }
      catch (error) {
                 console.log("You may be interested:");
                 console.log(error);
             }
    };  

    return (
      <ThemeProvider theme={theme}>
      <Container key="introContainer" component="main" maxWidth="xl">
        <CssBaseline />
        <Box key="introBox"
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          {user ? (
           <>
            <Avatar key="infoIconAuthenticated" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <PlayCircleOutlineRoundedIcon />
            </Avatar>
            <Typography key="infoTextAuthenticated" component="h1" variant="h5">
               Intro to PAXHELPER               
            </Typography>
            <br />

            <Grid container spacing={3}>
              <Grid item xl={4} md={4} xs={12}>
                    <Paper
                  sx={{
                    p: 2,
                    margin: 'auto',
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item>          
                      <ButtonBase sx={{ width: 128, height: 128 }}>            
                        <ModalImage alt="Flight search" small="https://paxhelper.com/wp-content/uploads/2023/11/00.png" large="https://paxhelper.com/wp-content/uploads/2023/11/00.png" />
                      </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>              
                          <Typography gutterBottom variant="subtitle1" component="div">
                          <strong> Flight search </strong>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            1-7 years data history • add own data 
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Test: All WizzAir routes
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ cursor: 'pointer' }} variant="body2">
                          <Button onClick={handlRedirectFlightSearch}>Try now</Button>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" component="div">
                          Manual
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xl={4} md={4} xs={12}>
              <Paper
            sx={{
              p: 2,
              margin: 'auto',
              maxWidth: 500,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            <Grid container spacing={2}>
              <Grid item>          
                <ButtonBase sx={{ width: 128, height: 128 }}>            
                  <ModalImage alt="Data analytics" small="https://paxhelper.com/wp-content/uploads/2023/08/1.png" large="https://paxhelper.com/wp-content/uploads/2023/08/1.png"  />
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>              
                    <Typography gutterBottom variant="subtitle1" component="div">
                    <strong>Data analytics</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Jurisdiction • Compensation • Weather
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Test: canceled • delayed • diverted
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                    
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="div">
                    Manual
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
              </Grid>
              <Grid item xl={4} md={4}  xs={12}>
              <Paper
            sx={{
              p: 2,
              margin: 'auto',
              maxWidth: 500,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            <Grid container spacing={2}>
              <Grid item>          
                <ButtonBase sx={{ width: 128, height: 128 }}>            
                  <ModalImage alt="Smart templates" small="https://paxhelper.com/wp-content/uploads/2023/08/3.png" large="https://paxhelper.com/wp-content/uploads/2023/08/3.png" />
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>              
                    <Typography gutterBottom variant="subtitle1" component="div">
                      <strong>Smart templates</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      GDPR coplient • No client data stored
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Test: Quick placeholders replace
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                    <Button onClick={handlRedirectSmartTemplate}>Try now</Button>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="div">
                    Manual
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
              </Grid>              
            </Grid>

          <br/>
            {/* novy riadok */}
            <Grid container spacing={3}>
              <Grid item xl={4} md={4}  xs={12}>
                    <Paper
                  sx={{
                    p: 2,
                    margin: 'auto',
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item>          
                      <ButtonBase sx={{ width: 128, height: 128 }}>            
                        <ModalImage alt="Multilanguage templates" small="https://paxhelper.com/wp-content/uploads/2023/08/2.png" large="https://paxhelper.com/wp-content/uploads/2023/08/2.png" />
                      </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>              
                          <Typography gutterBottom variant="subtitle1" component="div">
                          <strong> Multilanguage templates </strong>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            Unlimited tempaltes • own languages 
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Test: Edit canceled flight template
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ cursor: 'pointer' }} variant="body2">
                          <Button onClick={handleRedirectTemplates}>Try now</Button>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" component="div">
                          Manual
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xl={4} md={4}  xs={12}>
              <Paper
            sx={{
              p: 2,
              margin: 'auto',
              maxWidth: 500,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            <Grid container spacing={2}>
              <Grid item>          
                <ButtonBase sx={{ width: 128, height: 128 }}>            
                  <ModalImage alt="PNR reports" small="https://paxhelper.com/wp-content/uploads/2023/08/1.png" large="https://paxhelper.com/wp-content/uploads/2023/08/1.png" />
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>              
                    <Typography gutterBottom variant="subtitle1" component="div">
                    <strong>PNR reports</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Evidence • Auditing
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Test: Setup own PNR report text
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                    
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="div">
                    Manual
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
              </Grid>
              <Grid item xl={4} md={4}  xs={12}>
              <Paper
            sx={{
              p: 2,
              margin: 'auto',
              maxWidth: 500,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            <Grid container spacing={2}>
              <Grid item>          
                <ButtonBase sx={{ width: 128, height: 128 }}>            
                  <ModalImage alt="Currency exchange" small="https://paxhelper.com/wp-content/uploads/2023/11/6.png" large="https://paxhelper.com/wp-content/uploads/2023/11/6.png"/>
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>              
                    <Typography gutterBottom variant="subtitle1" component="div">
                      <strong>Currency exchange</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Automated conversion • set own rates
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Test: Convert $$ to "€€"
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                    <Button onClick={handleRedirectCurrency}>Try now</Button>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="div">
                    Manual
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
              </Grid>              
            </Grid>

            <br/>
            {/* novy riadok */}
            <Grid container spacing={3}>
              <Grid item xl={4} md={4}  xs={12}>
                    <Paper
                  sx={{
                    p: 2,
                    margin: 'auto',
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item>          
                      <ButtonBase sx={{ width: 128, height: 128 }}>            
                        <Img alt="complex" src="http://paxhelper.com/wp-content/uploads/2023/11/api-scaled.jpeg" />
                      </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>              
                          <Typography gutterBottom variant="subtitle1" component="div">
                          <strong> API Integration </strong>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            CRM • ERP • Salesforce
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ cursor: 'pointer' }} variant="body2">
                            Ask for demo
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" component="div">
                          Automated
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xl={4} md={4}  xs={12}>
              <Paper
            sx={{
              p: 2,
              margin: 'auto',
              maxWidth: 500,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >
            <Grid container spacing={2}>
              <Grid item>          
                <ButtonBase sx={{ width: 128, height: 128 }}>            
                  <Img alt="complex" src="http://paxhelper.com/wp-content/uploads/2023/11/ai-scaled.jpeg" />
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>              
                    <Typography gutterBottom variant="subtitle1" component="div">
                    <strong>AI Response generator</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      AI Customer care • Paraphrasing
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Test: Automated claim resolution service
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                    Ask for demo
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="div">
                    AI
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
              </Grid>
              <Grid item xl={4} md={4}  xs={12}>

              </Grid>              
            </Grid>

         
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/iLsyEl8O4aI" title="Intro video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen='true'></iframe> */}
             <Button
              key="logout"
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={logout}
            >
              Sign Out
            </Button>
           </>
         ) : (
          <>
          <Box key="logobox" textAlign={'cener'}><div><img src={logoUrl} alt="Logo of the customer" height="60" style={{margin:'100px 0px 0px 0px'}}/></div>                 
          </Box>
          <Box key="formbox" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              key="logintextfield"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              key="passowrtextfield"
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link key="forgotPwdLink" href="/pwdresetrequest" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link key="requestAccessLink" href={contactToGetAnAccount} variant="body2">
                  Need PAXHELPER? Contact us
                </Link>
              </Grid>
            </Grid>
          </Box>
          </>
          )}
        </Box>
        <Copyright key="copyrightBlock" sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    );
  //  async function signIn (myEmail, myPassword){
  //       console.log('Login reports: this is value of "authenticated" variable on START of the methot GetMeIn:');
  //       console.log(authenticated);
  //     try {       
  //           const { data, error } = await supabase.auth.signInWithPassword({
  //               email: myEmail,
  //               password: myPassword
  //           })
  //           if (error) throw error;
  //           console.log(data);
  //           setAuthenticated(data);
  //           console.log('seting "data" to local vairable "ahthenticaded"');
  //           localStorage.setItem("authenticated", data);
  //           console.log('seting "data" to local storage');
  //           console.log('Login reports: this is value of "authenticated" variable at the END of the methot GetMeIn:');
  //           console.log(authenticated);
  //           navigate('/flight');
            
  //       }
  //       catch (error) {
  //           console.log("You may be interested:");
  //           console.log(error);
  //       }
  //   }
  //  return (
  //   <div class="login">
  //     <h1>Airline Customer Care Decision Support System</h1>
  //     <p>beta version for Airline service companies developed and provided bypaxhelper.com</p>  
  //     <p>paxhelper.com evaluates global flight data in real time to identify disrupted flights<br /> and provide best possible customer service for air passengers in need.</p>        
  //     <div>
  //       <img src="../../images/airlawpro_logo.png" alt="paxhelper.com" height="50" style={{margin:'18px'}}/>
  //     </div>
  //       { console.log(authenticated)}
  //       {authenticated ? (
  //         <div>
  //           <h1>Allready authenticated</h1>
  //           <button onClick={logout}>Logout</button>
  //         </div>
  //       ) : (
  //       <div>
  //         <input value={email} onChange={(e) => setEmail(e.target.value)}></input>
  //         <input value={password} onChange={(e) => setPassword(e.target.value)}></input>      
  //         <button onClick={(e) =>{e.preventDefault();  signIn(email, password)}}>Login</button>
  //       </div>
  //       )}
  //     <div class="login-logos">
  //       <span class="bottom-reff">
  //         <img src="../../images/partners/1.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/2.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/3.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/4.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/5.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/6.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //       </span>        
  //     </div>
  //   </div>    
  // ); 


};

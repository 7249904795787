import "../styles.css";
import React, { useRef, useLayoutEffect, useState } from 'react';
import FlightSelectionForm from "./flightsearch";
import BasicTabs from "../components/editor/BasicTabs";
import HorizontalTabs from "../components/editor/HorizontalTabs";
import { Navigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Editor from "../components/editor/Editor";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { supabase } from "../lib/supabase";
import { useUser } from "../components/authContextProvider/AuthContext";
import CurrencyConvertDrawer from "../components/currency/CurrencyConvertDrawer";


const Message = (props) => {
  const { user } = useUser();
  //const emptyTemplateContent = '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"toto je uplne namakany template 3 zo stringu","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}';
  const queryParameters = new URLSearchParams(window.location.search);
 // const [queryParametersState, setQueryParametersState] = React.useState(queryParameters);  
 const templateId = queryParameters.get("templateId");
//  const [templateId, setTemplateId] = React.useState(queryParametersState.get("templateId"));  
  const templateLanguage = queryParameters.get("lang")
  return (
    <div className="App">
      {user ? (<Box>
        <Box padding={3} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Editor user={user} templateId={templateId} templateLanguage={templateLanguage}/>
            <CurrencyConvertDrawer />
          </Grid>
        </Grid>
      </Box>
      ) : (
        <Navigate replace to="/login" />
      )}
    </div>
  )
}
export default Message;
import React, { useEffect, useState } from 'react';
import {supabase} from '../../lib/supabase';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Typography from '@mui/material/Typography';
import { InputLabel, NativeSelect, Select } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from '../authContextProvider/AuthContext';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import Alert from '@mui/material/Alert';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import CurrencyInput from "./CurrencyInput";
import axios from "axios";


export default function CurrencyConverter(props) {

  const [amount1, setAmount1] = useState(1);
  const [amount2, setAmount2] = useState(1);
  const [currency1, setCurrency1] = useState('USD');
  const [currency2, setCurrency2] = useState('EUR');
  const [rates, setRates] = useState([]);



  ///old
  const [templateId,setTemplateId] = React.useState('');
  const [sumToConvert,setSumToConvert] = React.useState(0);
  const [convertFrom,setConvertFrom] = React.useState('EUR');
  const [convertTo,setConvertTo] = React.useState('');
  const [userReport, setReport] = React.useState('');
  const [userCategory, setCategory] = React.useState('99');



  const [resultOfUpdateVisualize, setResultOfUpdateVisualize] = useState(false);  
  const [myPersonalEmanuelUserData, setMyPersonalEmanuelUserData] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null); 
  const [isPrivate, setIsPrivate] = useState(myPersonalEmanuelUserData.is_owner);  
  const { user } = useUser();

  const navigate = useNavigate(); 
 ///old

  useEffect(() => {
    setTimeout(() => {
      setResultOfUpdateVisualize(false);    
    }, 10000);    
  }, [resultOfUpdateVisualize]);

  useEffect(() => {
    axios.get('http://data.fixer.io/api/latest?access_key=78b930c7b303808512c260a47c4f9cfc')
      .then(response => {
        setRates(response.data.rates);
      })
  }, []);

  useEffect(() => {
    if (!!rates) {
      function init() {
        handleAmount1Change(1);
      }
      init();
    }
  }, [rates]);


  function format(number) {
    return number.toFixed(4);
  }

  function handleAmount1Change(amount1) {
    setAmount2(format(amount1 * rates[currency2] / rates[currency1]));
    setAmount1(amount1);
  }

  function handleCurrency1Change(currency1) {
    setAmount2(format(amount1 * rates[currency2] / rates[currency1]));
    setCurrency1(currency1);
  }

  function handleAmount2Change(amount2) {
    setAmount1(format(amount2 * rates[currency1] / rates[currency2]));
    setAmount2(amount2);
  }

  function handleCurrency2Change(currency2) {
    setAmount1(format(amount2 * rates[currency1] / rates[currency2]));
    setCurrency2(currency2);
  }


  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://paxhelper.com">
        paxhelper.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
  }
   
  const theme = createTheme();   

  const onChangeCategory = (event) => {
    setCategory(event.target.value)
  }

  const onChangeReport = (event) => {
    setReport(event.target.value)
  }
 
  const handlePrivateChange = () => { 
        setIsPrivate(!isPrivate);     
  }; 

  const onChangeConvertFrom = (event) => {
    setConvertFrom(event.target.value)
  }
 
  const onChangeConvertTo = (event) => {
    setConvertTo(event.target.value)
  }
 
  const onChangeSumToConvert = (event) => {
    setSumToConvert(event.target.value)
  }
  
  async function handleSubmit (event) {
      try{
        event.preventDefault();
        console.log('userCategory', userCategory);
        console.log('userReport', userReport);
        console.log('salesforceId', salesforceId);
        console.log('props.user.email', props.myuser.email);
        console.log('props.user.id', props.myuser.id);
        console.log('props.correlationID', props.activityCorrelationID);
        console.log('props.dropzone', props.dropzone);
        const form_data = new FormData(event.currentTarget);

        const { data, error } = await supabase
        .from('logMistake')
        .insert([
          { user_email: props.myuser.email, user_id: props.myuser.id, user_findings: userReport, correlation_id: props.activityCorrelationID, user_selected_category: userCategory, daniel_json: props.danielJson, template_filter: props.filters, dropzone: props.dropzone, salesforce_id: salesforceId, is_private: isPrivate },
        ])
        
        if (error)
        {  
          console.log(error);
           throw error;          
        }
        console.log(data);
        if(data != null)
        {
          setResultOfUpdateVisualize(true);
        }
        
      }
      catch (error) {
                 console.log("You may be interested:");
                 console.log(error);
             }
    };  

    return (
      <ThemeProvider theme={theme}>
      <Container key="introContainer" component="main" maxWidth="xs">
        <CssBaseline />
        <Box key="introBox"
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


            <Avatar key="infoIconReportMistake" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <CurrencyExchangeRoundedIcon />
            </Avatar>
            <Box key="formbox" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>           
            {(props.closeDrawer == null) && (resultOfUpdateVisualize) && <Alert severity="success">Reported successfully!</Alert> }
            {/* <h1>You found mistake? </h1>
            <p>Thank you for letting us know. Fixing flights help your colleagues get more precise information</p> */}
            <h1>Convert Currency</h1>
            <p>Currency rates are refreshed from API every 60 min. Rates can be defined "manually" by manager.</p>
            <div>
            <CurrencyInput
              onAmountChange={handleAmount1Change}
              onCurrencyChange={handleCurrency1Change}
              currencies={Object.keys(rates)}
              amount={amount1}
              currency={currency1} />
            <CurrencyInput
              onAmountChange={handleAmount2Change}
              onCurrencyChange={handleCurrency2Change}
              currencies={Object.keys(rates)}
              amount={amount2}
              currency={currency2} />
          </div>
            {/* <label id="label1">Category:  </label> */}
            {/* <NativeSelect                            
              defaultValue={userCategory}          
              inputProps={{
                name: 'mistakes',
                id: 'uncontrolled-native',
              }}
              onChange={onChangeCategory}
              fullWidth={100}
              >
              <option value={'all_ok'}>ALL OK - Correct flight evaluation and templates</option>
              <option value={'categorization'}>Wrong caregorization (cancelation/delay/other)</option>
              <option value={'compensation'}>Wrong compensation</option>
              <option value={'jurisdiction'}>Wrong jurisdiction</option>          
              <option value={'departure_airport'}>Wrong departure airport evaluation</option>
              <option value={'arrival_airport'}>Wrong arrival airport evaluation</option>
              <option value={'template_preselect'}>Wrong template pre selected</option>
              <option value={'other'}>Other problem</option>
            </NativeSelect> */}
            {/* {myPersonalEmanuelUserData.is_owner && <label>Log in private mode:</label>}
            {myPersonalEmanuelUserData.is_owner && <Checkbox id='isPrivate' name='isPrivate' checked={isPrivate} onClick={handlePrivateChange} /> }                                 */}
            {/* {(props.closeDrawer != null) &&<Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={props.closeDrawer(false)}
            >
              Submit
            </Button>} */}
            {(props.closeDrawer == null) &&<Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}              
            >
              Report
            </Button>}
            <Button onClick={props.closeDrawer(false)}>Close</Button>         
          </Box>           
        </Box>
        <Copyright key="copyrightBlock" sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    );
};

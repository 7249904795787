import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ReportMistake from './ReportMistake';
import Fab from '@mui/material/Fab';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import OrderNow from './OrderNow';



export default function ReportMistakeDrawer(props) {
  const [state, setState] = React.useState({});

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
    //onClick={toggleDrawer(anchor, false)}
    //onKeyDown={toggleDrawer(anchor, false)}
    >
      <OrderNow closeDrawer={() => toggleDrawer(anchor, false)} emanuelUser={props.emanuelUser}  danielJson={props.danielJson} filters={props.filters} activityCorrelationID={props.activityCorrelationID}  myuser={props.myuser} dropzone={props.dropzone}/>
      <Divider />

    </Box>
  );

  return (
    <>
      {
        <React.Fragment key='New'>
          <Box class="fab-container" sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab variant="extended" onClick={toggleDrawer('New', true)}>
              <ListAltRoundedIcon sx={{ mr: 1 }} />
              GET FREE TRIAL
            </Fab>
          </Box>
          <Drawer
            anchor='right'
            open={state['New']}
            onClose={toggleDrawer('New', false)}
          >
            {list('New')}
          </Drawer>
        </React.Fragment>
      }
    </>



  );
}
import React, { useState } from 'react';
import {supabase} from '../lib/supabase';
import { useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function PwdResetRequest() {

   const navigate = useNavigate(); 
   const[email, setEmail] = useState('');
   const[password, setPassword] = useState('');
   const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false); 
  
   function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://paxhelper.com">
          paxhelper.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
   }

   const theme = createTheme();
   async function logout(){          
          localStorage.removeItem("authenticated");
          setAuthenticated(false);
       		await supabase.auth.signOut();
   }
   
    async function handleSubmit (event) {
      try{
        event.preventDefault();
        const form_data = new FormData(event.currentTarget);
        const { data, error } = await supabase.auth.resetPasswordForEmail(form_data.get('email'), {
          redirectTo: 'https://sc.paxhelper.com/pwdresetform',
        })          
        if (error) throw error;
        setAuthenticated(null);
        localStorage.removeItem("authenticated");
        navigate('/pwdresetinfo');        
      }
      catch (error) {
                  console.log("You may be interested:");
                 console.log(error);
             }
    };  

    return (
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockResetOutlinedIcon />
          </Avatar>
          <>
          <Typography component="h1" variant="h5">
            Request to reset your password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />  
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Request Password Reset
            </Button>
            <Grid container>            
            </Grid>
          </Box>
          </>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    );
  //  async function signIn (myEmail, myPassword){
  //       console.log('Login reports: this is value of "authenticated" variable on START of the methot GetMeIn:');
  //       console.log(authenticated);
  //     try {       
  //           const { data, error } = await supabase.auth.signInWithPassword({
  //               email: myEmail,
  //               password: myPassword
  //           })
  //           if (error) throw error;
  //           console.log(data);
  //           setAuthenticated(data);
  //           console.log('seting "data" to local vairable "ahthenticaded"');
  //           localStorage.setItem("authenticated", data);
  //           console.log('seting "data" to local storage');
  //           console.log('Login reports: this is value of "authenticated" variable at the END of the methot GetMeIn:');
  //           console.log(authenticated);
  //           navigate('/flight');
            
  //       }
  //       catch (error) {
  //           console.log("You may be interested:");
  //           console.log(error);
  //       }
  //   }
  //  return (
  //   <div class="login">
  //     <h1>Airline Customer Care Decision Support System</h1>
  //     <p>beta version for Airline service companies developed and provided bypaxhelper.com</p>  
  //     <p>paxhelper.com evaluates global flight data in real time to identify disrupted flights<br /> and provide best possible customer service for air passengers in need.</p>        
  //     <div>
  //       <img src="../../images/airlawpro_logo.png" alt="paxhelper.com" height="50" style={{margin:'18px'}}/>
  //     </div>
  //       { console.log(authenticated)}
  //       {authenticated ? (
  //         <div>
  //           <h1>Allready authenticated</h1>
  //           <button onClick={logout}>Logout</button>
  //         </div>
  //       ) : (
  //       <div>
  //         <input value={email} onChange={(e) => setEmail(e.target.value)}></input>
  //         <input value={password} onChange={(e) => setPassword(e.target.value)}></input>      
  //         <button onClick={(e) =>{e.preventDefault();  signIn(email, password)}}>Login</button>
  //       </div>
  //       )}
  //     <div class="login-logos">
  //       <span class="bottom-reff">
  //         <img src="../../images/partners/1.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/2.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/3.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/4.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/5.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //         <img src="../../images/partners/6.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
  //       </span>        
  //     </div>
  //   </div>    
  // ); 


};

import "../App.css";
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Box } from '@mui/system';
import { NativeSelect, Typography } from "@mui/material";
import { supabase } from "../lib/supabase";
import { useUser } from '../components/authContextProvider/AuthContext';
import uuid from 'react-uuid';
import ReportMistakeDrawer from "../components/mistakes/ReportMistakeDrawer";
import Checkbox from '@mui/material/Checkbox';


export default function FlightSearch() {

  const [dateFlightValue, setDateFlightValue] = React.useState(dayjs());
  const [dateFlightString, setDateFlightString] = React.useState(isoDateWithoutTimeZone(dayjs().$d));
  const [routeValue, setRouteValue] = React.useState(null);
  const [airlineValue, setAirlineValue] = React.useState('--');
  const [flightNumber, setFlightNumber] = React.useState(null);
  const [fromIATA, setFromIATA] = React.useState("");
  const [toIATA, setToIATA] = React.useState("");
  const [danielUrl, setDanielUrl] = useState('');
  const [danielToken, setDanielToken] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [logoInteractiveUrl, setLogoInteractiveUrl] = useState('');
  const [allowedAirlines, setAllowedAirlines] = useState([]);
  const [filteredRoutes, setfilteredRoutes] = useState([]); 
  const [view, switchView] = useState(false);
  const [loading, switchLoading] = useState(false);
  const [dataToVizualize, setDataToVizualize] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();  
  const [myPersonalEmanuelUserData, setMyPersonalEmanuelUserData] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null); 
  const [noCache, setNoCache] = React.useState(myPersonalEmanuelUserData.is_owner);  
  const [dataSource, setDataSource] = useState('both');
  const [allAirlinesOption, setAllAirlinesOption] = React.useState({id:0, IATA: "--",  ICAO: "XXX", CallSign: "Any", Name: "Any", Country: "XX"});

  useEffect(() => {    
    if ((!view)) {
      (async function () {
        console.log("start downloading airlines");
        const { data, error } = await supabase.from('airlines').select().order('IATA', { ascending: true });
        if (error) {
          console.log("I`m the FLIGHTSEARCH PAGE prepAllowedAirlines Function returning error:")
          console.log(error);
          throw error;
        }
        else {
          console.log("I`m the FLIGHTSEARCH PAGE prepAllowedAirlines Function returning data:")
          console.log(data);
          data.push(allAirlinesOption);
          setAllowedAirlines(data);
        }
      })();
    }
    else {
      (async function () {
        console.log("start downloading routes");
        const { data, error } = await supabase.from('routes').select('flight_icao,dep_iata,arr_iata,dep_time, arr_time, days');

        if (error) {
          console.log("I`m the FLIGHTSEARCH PAGE prepRoutes Function returning error:")
          console.log(error);
          throw error;
        }
        else {
          console.log("I`m the FLIGHTSEARCH PAGE prepRoutes Function returning data:")
          console.log(data);          
          setfilteredRoutes(data);
          console.log(data.length)
        }
      })();
    }
    setLogoUrl(process.env.REACT_APP_LOGO_URL);
    setLogoInteractiveUrl(process.env.REACT_APP_LOGO_INTERACTIVE_URL);
    setDanielUrl(process.env.REACT_APP_DANIEL_URL);
    setDanielToken(process.env.REACT_APP_DANIEL_TOKEN);    
  }, [view]);

  useEffect(()=> {
    console.log("Applying use effect function", airlineValue);

    if((airlineValue != null )&& (airlineValue != "") && (flightNumber!= null ) && (flightNumber >0 ) && ((toIATA == null)|| (toIATA =="")) && ((fromIATA == null)||(fromIATA =="")))
    {
      console.log("Going to extend the route");      
        (async function () {
          console.log("start downloading routes");
          const { data, error } = await supabase.from('distinct_routes').select('airline_iata, airline_icao, arr_iata, dep_iata').eq('airline_icao', airlineValue).eq('flight_number', flightNumber);
          if (error) {
            console.log("I`m the FLIGHTSEARCH PAGE  routeAutocomplete useEffect Function returning error:")
            console.log(error);
            throw error;
          }
          else {
            console.log("I`m the FLIGHTSEARCH PAGE  routeAutocomplete useEffect Function returning data:")
            console.log(data);            
            if(data?.length > 0)
            {
               setFromIATA(data[0].dep_iata);
               setToIATA(data[0].arr_iata);               
            }
          }
        })();
    }
    else if((fromIATA != null )&& (fromIATA != "") &&(toIATA != null )&& (toIATA != "") && (flightNumber!= null ) && (flightNumber >0 ) && ((airlineValue == null )|| (airlineValue == "") || (airlineValue=="--")  || (airlineValue=="XXX")) )
    {
      console.log("Going to extend the route IM INSIDE");      
      (async function () {
        console.log("start downloading routes");
        const { data, error } = await supabase.from('distinct_routes').select('airline_iata, airline_icao, arr_iata, dep_iata').eq('dep_iata', fromIATA).eq('arr_iata', toIATA).eq('flight_number', flightNumber);
        if (error) {
          console.log("I`m the FLIGHTSEARCH PAGe routeAutocomplete useEffect Function returning error:")
          console.log(error);
          throw error;
        }
        else {
          console.log("I`m the FLIGHTSEARCH PAGE  routeAutocomplete useEffect Function returning data:")
          console.log(data);            
          if(data?.length > 0)
          {
             setAirlineValue(data[0].airline_icao);             
          }
        }
      })();
    }

  }, [flightNumber,fromIATA,toIATA,airlineValue]);


  function isoDateWithoutTimeZone(date) {
    if (date == null) return date;
    var timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
    var correctDate = new Date(timestamp);
    // correctDate.setUTCHours(0, 0, 0, 0); // uncomment this if you want to remove the time
    return correctDate.toISOString();
  }

  async function logActivity (userData, activity, myExtras)
  {
    console.log('!!! Creating log activity for user :',userData);
    const activityCorrelationID = uuid();
    sessionStorage.setItem('activityCorrelationID', activityCorrelationID)
    const { data, error } = await supabase
      .from('logUserActivity')
      .insert([
        { user_email: userData.email, user_id: userData.id, type: activity, correlationID: activityCorrelationID, extras: myExtras },
      ])
  }

  const handleMoreFlights = () =>{
    console.log('Adding more flight input:');
  }

  const handleDateChange = (newDateValue) => {    
    setDateFlightValue(newDateValue);
    console.log('Date value to debug the problem with delete:');
    console.log(newDateValue);
    if (newDateValue != null) {
      if (newDateValue.$d != 'Invalid Date') {
        //newDateValue.setUTCHours(0,0,0,0);        
        setDateFlightString(isoDateWithoutTimeZone(newDateValue.$d))
        //const month = newDateValue.$M+1;
        //console.log(month);
        //setDateFlightString(newDateValue.$y+"-"+month+"-"+newDateValue.$D)
      }
    }
  };

  
  const handleFlightNumberChange = (event) => {  
    if((event != null) && (event.target != null) && (event.target.value != null))  
    {
      setFlightNumber(event.target.value);
    }
  }

  const handleFromChange = (event) => {  
    if((event != null) && (event.target != null) && (event.target.value != null))  
    {
       setFromIATA(event.target.value);
    }
  }

  const handleToChange = (event) => {  
    if((event != null) && (event.target != null) && (event.target.value != null))  
    {
       setToIATA(event.target.value);
    }
  }
  const handleNoCacheChange = (event) => {     
    setNoCache(!noCache);
  }; 
  const handleDataSourceChange = (event) => {     
    setDataSource(event.target.value);
  }; 
  

  const handleDropZoneChange = (event) => {  
    if((event != null) && (event.target != null) && (event.target.value != null))  
    {
        
        //var airlinePater = "\\b(?:[Ww][4-9]|5[Ww])\\s*\\d*\\b";
        var airlinePater1 = "\\b(?:[Ww][4-9]|5[Ww])\\b";
        var airlinePater2 = "\\b(?:[Ww][4-9]|5[Ww])(?!\\s)";

        var airportsPatern1 = "\\b[A-Z]{3}\\s*-\\s*[A-Z]{3}(?!\\s)";
        var airportsPatern2 = "\\b[A-Z]{3}\\s*-\\s*[A-Z]{3}(?!\\S)";

        //var flightNumberPatern = "(?:\\s*(?:5W|W[469]|W4))?\\d{4}(?!\\d)(?=\\s|\\S)|(?:\\s*(?:5W|W[469]|W4))?\\d{3}(?!\\d{1})(?=\\s|\\S)";
        var flightNumberPatern = "(?:\\s*(?:5W|w[469]|w4))?\\d{4}(?!\\d)(?=\\s|\\S)|(?:\\s*(?:5W|w[469]|w4))?\\d{3}(?!\\d{1})(?=\\s|\\S)";        

        //"(?:\\s*(?:5W|W[469]|W4))?\\d{3,4}(?!\\d)(?=\\s|\\S)"; //second alternative
        //var flightNumberPater = "\\b\\d{3,4}(?!\\s)"; //original
        var datePatern = "(\\b\\d{1,2}\\/\\d{1,2}\\/\\d{4}\\b)|(\\b\\d{1,2}\\/\\d{2}\\/\\d{4}\\b)";      
        var datePatern2 = "(\\b\\d{1,2}\\/\\d{1,2}\\/\\d{4}\\b)|(\\b\\d{1,2}\\/\\d{2}\\/\\d{4}\\b)|(\\b\\d{4}-\\d{2}-\\d{2}\\b)";
        var datePatern3 = "(\\b\\d{1,2}\\/\\d{1,2}\\/\\d{4}\\b)|(\\b\\d{1,2}\\/\\d{2}\\/\\d{4}\\s)";      
        var datePatern4 = "(\\b\\d{1,2}\\/\\d{1,2}\\/\\d{4}\\b)|(\\b\\d{1,2}\\/\\d{2}\\/\\d{4}\\b)|(\\b\\d{4}-\\d{2}-\\d{2}\\S)";                
        var datePatern5 = "^(0[1-9]|[12][0-9]|3[01])\\.(0[1-9]|1[0-2])\\.\\d{4}$";
        var datePatern6 = "^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[0-2])\\.\\d{4}$";



        var stringToAnalyse = "";
        var flightDate = "";        
        var localFlightNumber = "";
        var airports1 = "";
        var airports2 = "";

        var localAirline1 = "";
        var localAirline2 = "";


        stringToAnalyse = event.target.value;
        localAirline1 = stringToAnalyse.match( airlinePater1 );
        localAirline2 = stringToAnalyse.match( airlinePater2 );

        localFlightNumber = stringToAnalyse.match( flightNumberPatern );
        airports1 = stringToAnalyse.match( airportsPatern1 );
        airports2 = stringToAnalyse.match( airportsPatern2 );
        var dateMatch  = stringToAnalyse.match( datePatern );        
        var dateMatch2  = stringToAnalyse.match( datePatern2 );        
        var dateMatch3  = stringToAnalyse.match( datePatern3 );        
        var dateMatch4  = stringToAnalyse.match( datePatern4 );        
        var dateMatch5  = stringToAnalyse.match( datePatern6 );        

        if((localFlightNumber != null) &&(localFlightNumber != "")) setFlightNumber(localFlightNumber[0]);  


        var firstDate = dateMatch ? dateMatch[0] : null;
        var secondDate = dateMatch2 ? dateMatch2[0] : null;
        var thirdDate = dateMatch3 ? dateMatch3[0] : null;
        var forthDate = dateMatch4 ? dateMatch4[0] : null;
        var fifthDate = dateMatch5 ? dateMatch5[0] : null;
        if(firstDate != null) 
        {
          setDateFlightValue(dayjs(firstDate));       
          handleDateChange(dayjs(firstDate));
        }
        else if(secondDate != null) 
        {
          setDateFlightValue(dayjs(secondDate));       
          handleDateChange(dayjs(secondDate));
        }
        else if(thirdDate != null) 
        {
          setDateFlightValue(dayjs(thirdDate));       
          handleDateChange(dayjs(thirdDate));
        }
        else if(forthDate != null) 
        {
          setDateFlightValue(dayjs(forthDate));       
          handleDateChange(dayjs(forthDate));
        }
        else if(fifthDate != null) 
        {
          console.log("This is fifthDate value to process:", fifthDate);
          const partsxxx = fifthDate.split('.');
          console.log( partsxxx.length);
          if( partsxxx.length == 3)
          {
            const day = partsxxx[0];
            const month = partsxxx[1];
            const year = partsxxx[2];
        
            const formattedDate = `${month}/${day}/${year}`;
            setDateFlightValue(dayjs(formattedDate));       
            handleDateChange(dayjs(formattedDate));
          }
        }

        if((airports2 != null) &&(airports2 != "")) 
        {
          var tmpstring = airports2[0];
          setFromIATA(tmpstring.slice(0, 3));
          setToIATA(tmpstring.slice(-3));
          console.log(fromIATA);  
          console.log(toIATA);  
        }        
        else if((airports1 != null) &&(airports1 != "")) 
        {
          var tmpstring = airports1[0];
          setFromIATA(tmpstring.slice(0, 3));
          setToIATA(tmpstring.slice(-3));
          console.log(fromIATA);  
          console.log(toIATA);  
        }
        console.log("LocalAirline1 :",localAirline1);
        console.log("LocalAirline2 :",localAirline2);

        if((localAirline1 != null) &&(localAirline1 != "")) 
        {
          var tmpAirlineString = localAirline1[0];
          allowedAirlines.forEach((airline1) => {
            if (airline1.IATA === tmpAirlineString) {
              // Match found
              // Perform actions or store the matched object as needed
              console.log("Match found:", airline1.ICAO);
              setAirlineValue(airline1.ICAO);
            }
          });
        }
        else  if((localAirline2 != null) &&(localAirline2 != "")) 
        {
          var tmpAirlineString = localAirline2[0];
          allowedAirlines.forEach((airline2) => {
            if (airline2.IATA === tmpAirlineString) {
              // Match found
              // Perform actions or store the matched object as needed
              console.log("Match found:", airline2.ICAO);
              setAirlineValue(airline2.ICAO);
            }
          });
        }
    }
  }
 
  const handleTestButton1Click = async => {
    setAirlineValue("WMT");
    setFlightNumber(5566);
    setFromIATA("KEF");
    setToIATA("MXP");    
    setDateFlightValue(dayjs(`2023-09-29T00:00:00.000Z`)); 
    handleDateChange(dayjs(`2023-09-29T00:00:00.000Z`));
  }

  const handleTestButton2Click = async => {
    setAirlineValue("WZZ");
    setFlightNumber(1556);
    setFromIATA("SPU");
    setToIATA("WAW");    
    setDateFlightValue(dayjs(`2023-07-11T00:00:00.000Z`));     
    handleDateChange(dayjs(`2023-07-11T00:00:00.000Z`));
  }

  const handleTestButton3Click = async => {
    setAirlineValue("WMT");
    setFlightNumber(5791);
    setFromIATA("LGW");
    setToIATA("NAP");    
    setDateFlightValue(dayjs(`2023-07-16T00:00:00.000Z`)); 
    handleDateChange(dayjs(`2023-07-16T00:00:00.000Z`));

  }
  const handleTestButton4Click = async => {
    setAirlineValue("WMT");
    setFlightNumber(3762);
    setFromIATA("BLQ");
    setToIATA("CRA");    
    setDateFlightValue(dayjs(`2023-10-13T00:00:00.000Z`)); 
    handleDateChange(dayjs(`2023-10-13T00:00:00.000Z`));

  }

  const handleSubmit = async (event) => {

    //     switchLoading(!loading);
    // console.log('loading value');
    // console.log(loading);
    switchLoading(true);
    try {
      event.preventDefault();
      const form_data = new FormData(event.currentTarget);

      var cacheString = "use";
      if(noCache) 
        cacheString = "skip";

      if (view) {
        /////////////////////////////////////////
        // SECTION WITHOUT ANALYTICS
        /////////////////////////////////////////
        console.log(airlineValue);
        const myFlightNumber = flightNumber;
        const myDepartureAirport = fromIATA;
        const myArrivalAirport = toIATA;
        if (isNaN(myFlightNumber) || (myDepartureAirport.length != 3) || (myArrivalAirport.length != 3) || (!/^[a-zA-Z]+$/.test(myDepartureAirport)) || (!/^[a-zA-Z]+$/.test(myArrivalAirport)))
        {
          alert("Please provide correct input.");
          switchLoading(false);
          return false;          
        } 
        else {
          var url = danielUrl + "/minimalData/"+ danielToken +"/" + airlineValue.toUpperCase() +"/"+ myFlightNumber + "/" + myDepartureAirport.toUpperCase() + "/" + myArrivalAirport.toUpperCase() + "/"+ (dateFlightString) +"?dataSource="+ dataSource +"&useCache="+cacheString;
          console.log(url);
          await logActivity(user, 'mini-search', url + " "+airlineValue.toUpperCase()+" "+myFlightNumber+ " "+myDepartureAirport.toUpperCase() + " - " + myArrivalAirport.toUpperCase() + " "+ (dateFlightString) );          
          fetch(url)
            .then((res) => res.json())
            .then((data) => {
              setDataToVizualize(data)
              console.log(data);
              sessionStorage.setItem("AirLawDataEntity", JSON.stringify(data));              
              
              
              const haveData = setMinimalAttributes(data);
              
              console.log(haveData);              
              if (haveData)
              {                
                sessionStorage.setItem("AirLawDataEntity", JSON.stringify(data));
                navigate('/flightdetails');
                switchLoading(false);
              }
              else
              {
                console.log("tu vyhod modal okno")
                switchLoading(false);                
                alert("Data for this flight were not found. Are you sure you have a correct input? (check: less than 1 year old / IATA Airport Codes, selected AIRLINE ... Error can be also on EMANUELS END. Please report this flight topaxhelper.com through the button bellow.)");
              }
            })
            .catch((error) => {
              console.error(error);              
              switchLoading(false);
              alert(error);
            });
        }        



        // if (routeValue != null) {
        //   var pieces = routeValue.split(" ");

        //   var url = danielUrl + "/checkFlight/"+ danielToken +"/" + pieces[0].substring(0,3) + "/"+ pieces[0].substring(3) + "/" + pieces[4] + "/" +pieces[6] + "/" + (dateFlightString) +"?dataSource="+ dataSource +"&useCache="+cacheString;
        //   console.log(url);
        //   await logActivity(user, 'start-search', url);
        //   fetch(url)
        //     .then((res) => res.json())
        //     .then((data) => {
        //       setDataToVizualize(data)
        //       console.log(data);
        //       sessionStorage.setItem("AirLawDataEntity", JSON.stringify(data));              
        //       const haveData = analyzeDanielsData(data);
        //       console.log(haveData);
        //       if (haveData)
        //       {                
        //         sessionStorage.setItem("AirLawDataEntity", JSON.stringify(data));
        //         navigate('/flightdetails');
        //         switchLoading(false);
        //       }              
        //       else
        //       {
        //         console.log("tu vyhod modal okno")
        //         switchLoading(false);                
        //         alert("Data for this flight were not found. Are you sure you have a correct input? (check: more than 3 days / less than 1 year old / IATA Airport Code ...)");
        //       }

        //     })
        //     .catch((error) => {
        //       console.error(error);              
        //       switchLoading(false);
        //       alert(error);
        //     });

        //   //DEBUG:
        //   //console.log({ routeValue })
        //   //console.log({ dateFlightString })
        //   //navigate to 
        //   //props.changeMyAppData({ShowElements: 'true', Route: {routeValue}, Date:(dateFlightValue), Problem:(problemValue), AnswertTo:(answertoValue) });      
        // };
      }
      else {
        console.log(airlineValue);
        const myFlightNumber = flightNumber;
        const myDepartureAirport = fromIATA;
        const myArrivalAirport = toIATA;
        if (isNaN(myFlightNumber) || (myDepartureAirport.length != 3) || (myArrivalAirport.length != 3) || (!/^[a-zA-Z]+$/.test(myDepartureAirport)) || (!/^[a-zA-Z]+$/.test(myArrivalAirport)))
        {
          alert("Please provide correct input.");
          switchLoading(false);
          return false;          
        } 
        else {
          var url = danielUrl + "/checkFlight/"+ danielToken +"/" + airlineValue.toUpperCase() +"/"+ myFlightNumber + "/" + myDepartureAirport.toUpperCase() + "/" + myArrivalAirport.toUpperCase() + "/"+ (dateFlightString) +"?dataSource="+ dataSource +"&useCache="+cacheString;
          console.log(url);
          await logActivity(user, 'start-search', url + " "+airlineValue.toUpperCase()+" "+myFlightNumber+ " "+myDepartureAirport.toUpperCase() + " - " + myArrivalAirport.toUpperCase() + " "+ (dateFlightString) );          
          fetch(url)
            .then((res) => res.json())
            .then((data) => {
              setDataToVizualize(data)
              console.log(data);
              sessionStorage.setItem("AirLawDataEntity", JSON.stringify(data));              
              const haveData = analyzeDanielsData(data);
              console.log(haveData);              
              if (haveData)
              {                
                sessionStorage.setItem("AirLawDataEntity", JSON.stringify(data));
                navigate('/flightdetails');
                switchLoading(false);
              }
              else
              {
                console.log("tu vyhod modal okno")
                switchLoading(false);                
                alert("Data for this flight were not found. Are you sure you have a correct input? (check: less than 1 year old / IATA Airport Codes, selected AIRLINE ... Error can be also on EMANUELS END. Please report this flight topaxhelper.com through the button bellow.)");
              }
            })
            .catch((error) => {
              console.error(error);              
              switchLoading(false);
              alert(error);
            });
        }        
      }      
    }
    catch (error) {
      console.log("You may be interested:");
      console.log(error);
    }
  };
  //var dataRawRoutes = wazData.concat(wukData).concat(wzzData);
  function setMinimalAttributes(danielsData)
  {
    sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'noFilter', airlineFilter: 'allAirlines', responseOrderFilter: 'showAll', flightType: 'mini', jurisdictionFilter: null }));    
    return true;
  }

  function analyzeDanielsData (danielsData) {
    console.log("start analyzing Daniels Data");
    sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'noFilter', airlineFilter: 'allAirlines', responseOrderFilter: 'showAll', flightType: null, jurisdictionFilter: null }));    
    try {
      
      if((danielsData != "No flight found.") && (danielsData != null))
      {
        //console.log(danielsData);
        const airLawData = danielsData?.airLawData;      

        if (airLawData != null) {
        //  console.log(airLawData);          
          var myJurisdictionFilter = null;
          if(airLawData.flightEvaluationEngine != null )  
          {
              if (airLawData.polishAirline || airLawData.flightEvaluationEngine.polishFlight) myJurisdictionFilter = "%PL%";
              else if( airLawData.flightEvaluationEngine.germanAirlines || airLawData.flightEvaluationEngine.germanFlight ) myJurisdictionFilter = "%DE%";
              else if( airLawData.flightEvaluationEngine.austrianAirline || airLawData.flightEvaluationEngine.austrianFlight ) myJurisdictionFilter = "%AT%";
          }          
          if(airLawData.flightEvaluationEngine.notamsAlert) sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'notam', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter  }));
          else 
            if(airLawData.flightEvaluationEngine.covidFlightAlert) sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'covid', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter}));
            else 
              if(airLawData.flightEvaluationEngine.airportStrikeAlert) sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'airportStrike', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));
              else 
              {                
                if (airLawData.departure.scheduledTime != null)
                {
                  const date1 = new Date(airLawData.departure.scheduledTime);                
                  const today = dayjs();
          
                  let hours = today.diff(date1, 'hours');
                  const days = Math.floor(hours / 24);
                  hours = hours - (days * 24);
                  
                  console.log('Days: ', days);
                  console.log('Hours: ', hours); 
                  if(days >= 730) sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: '2years', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                  else
                  {
                    var delayToEvaluate = null;
                    if ((airLawData.status == "delay") && ((airLawData.departure?.delay != null) || (airLawData.arrival?.delay != null)))
                    {
                      if (airLawData.departure?.delay !== null && airLawData.arrival?.delay !== null)
                       {
                         delayToEvaluate = Math.max(airLawData.departure.delay, airLawData.arrival.delay);
                       } 
                       else if (airLawData.departure?.delay !== null) 
                       {
                        delayToEvaluate = airLawData.departure.delay;
                       } 
                       else 
                       {
                        delayToEvaluate = airLawData.arrival.delay;
                       }
                      if(delayToEvaluate >= 300) 
                      {
                        sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: '5hours', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                      }
                      else if(delayToEvaluate >= 180) sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: '3hours', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                     // else if(airLawData.departure.delay> 120) sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: '2hours', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                      else sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'delayLessThan3hours', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                    }                   
                    else if ((airLawData.status != "other") && (airLawData.flightEvaluationEngine != null) && ((airLawData.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation)||(airLawData.flightEvaluationEngine.itSeemsEligibleFOrBritishCompensation) ))
                    {
                      if(airLawData.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'payCompensation', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                      else sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'noFilter', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                    } 
                    else if ((airLawData.status == "other") && (airLawData.flightEvaluationEngine != null))
                    {
                      if(airLawData.statusType == "diverted") sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'diverted', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                      else sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'showAllways', airlineFilter: airLawData.airline.iataCode, responseOrderFilter: 'showAll', flightType: airLawData.status, jurisdictionFilter: myJurisdictionFilter }));                  
                    }
                  }                  
                }
              }

              return true;
        }
        else {
          console.log('HUSTON WE HAVE A PROBLEM: DANIES SUMMARY IS EMPTY - THERE IS NOTHING TO ANALYSE');
          sessionStorage.setItem("danielFilterSuggestion", JSON.stringify({ tagFilter: 'noFilter', airlineFilter: 'allAirlines', responseOrderFilter: 'showAll', flightType: null, jurisdictionFilter: null }));
          return false;
        }
      }
      else
      {
        console.log("Sem nastav alert");        
        return false;
      }
    }
    catch (e) {
      console.error(e);
      console.log();
      return false;
    }
  };





  const defaultProps = {
    //options: dataRawRoutes,
    options: filteredRoutes,
    getOptionLabel: (option) => option.flight_icao + " : " + option.dep_iata + " - " + option.arr_iata + " " + " ( " + option.dep_time + " - " + option.arr_time + " ) " + option.days,
  };
  // let routesOptions = new Array();
  // routesOptions = JSON.parse(JSON.stringify(dataRawRoutes));  

  return (
    <div className="App">
      {user ? (<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Box>
          {(loading)?<div><img src={logoInteractiveUrl} alt="Logo of the customer" height="60" style={{ margin: '100px 0px 0px 0px' }} /><h1>Searching flight...</h1></div> :
          <div><img src={logoUrl} alt="Logo of the customer" height="60" style={{ margin: '100px 0px 0px 0px' }} /><h1>Search flight</h1></div>}
        </Box>
        <Box>
        <Stack
          spacing={1}
          padding={{ xs: '2%', sm: '10% 10% 0% 10%' }}
          maxWidth={{ xs: '99%', sm: '80%' }}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'center', sm: 'center' }}
          alignContent={{ xs: 'center', sm: 'center' }}
          textAlign={{ xs: 'center', sm: 'center' }}>

          {/* {false &&
            <Box sx={{ width: '100%' }} xs={{ width: '100%' }}>
              <Autocomplete
                {...defaultProps}
                id="Route"
                name="Route"
                disableListWrap
                onChange={(Value) => { setRouteValue(Value.target.outerText) }}
                renderInput={(params) => (
                  <TextField {...params} label="Route" />
                )}
              />
              <Typography
                fontSize={'10px'}
                textAlign={'left'}
                padding={'0px 0px 0px 10px'}
              >Not on list?
                <Button style={{ fontSize: '10px' }} onClick={() => switchView(false)}>Switch to advanced view</Button>
              </Typography>
            </Box>
          } */}
          {true && <Box>

            <Stack sx={{ width: '100%' }} xs={{ width: '100%' }}
              spacing={1}
              maxWidth={{ xs: '100%', sm: '100%' }}
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'center', sm: 'center' }}
              alignContent={{ xs: 'center', sm: 'center' }}
              textAlign={{ xs: 'center', sm: 'center' }}>

              <Select style={{ minWidth: '100px' }}
                id="Airline"
                name="Airline"                                
                value={airlineValue}
                label="Airline"
                placeholder="Airline"
                onChange={(event) => { setAirlineValue(event.target.value) }}
              ><></>
                {allowedAirlines != null && allowedAirlines.map((allowedAirline, index) => (<MenuItem 
                  value={allowedAirline.ICAO}
                  key={allowedAirline.ICAO}
                >{allowedAirline.IATA} ({allowedAirline.ICAO}) - {allowedAirline.Name}</MenuItem>
                ))}
              </Select>
              <TextField id="flightNumberBox" name="flightNumber" label="Flight Number" variant="outlined" type="number" value={flightNumber}  InputLabelProps={{ shrink: true }}   onChange={handleFlightNumberChange}/>
              <TextField id="departureAirportBox" name="departureAirport" label="Departure Airport IATA" variant="outlined" value={fromIATA}  InputLabelProps={{ shrink: true }}  onChange={handleFromChange}/>
              <TextField id="arrivalAirportBox" name="arrivalAirport" label="Arrival Airport IATA" variant="outlined" value={toIATA}  InputLabelProps={{ shrink: true }}  onChange={handleToChange}/>

            </Stack>
            <Typography
              fontSize={'10px'}
              textAlign={'left'}
              padding={'0px 0px 0px 10px'}
            >Conected Flight?
              {/* <Button disabled style={{ fontSize: '10px' }} onClick={() => switchView(true)}>Switch to autocomplete view</Button> */}
              <Button style={{ fontSize: '10px' }} onClick={handleMoreFlights}>Enter connected flight</Button>
            </Typography>
          </Box>
          }

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker style={{ minWidth: '160px' }}
              id="FlightDate"
              name="FlightDate"
              label="Flight date"
              inputFormat="M/D/YYYY"
              minDate={"2020-05-15"}
              maxDate={dayjs()}
              value={dateFlightValue}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          {/* <FormControl>
          <FormLabel id="request-type-row-radio-buttons-group-label">Communication with:</FormLabel>
          
          <RadioGroup
            row
            aria-labelledby="request-type-radio-buttons-group-label"
            name="request-type-row-radio-buttons-group"
            onInput={(e) => setAnswerToValue(e.target.value)}
          >
            <FormControlLabel value="on-behalf" control={<Radio />} label="Agency/lawyer" />
            <FormControlLabel value="passenger" control={<Radio />} label="Passenger himself" />      
            <FormControlLabel
              value="group"
              disabled
              control={<Radio />}
              label="group"
            /> 
          </RadioGroup>
          </FormControl>  
          */}

          {/* <FormControl>
          <FormLabel id="problem-row-radio-buttons-group-label">Problem to solve:</FormLabel>
          <RadioGroup
            row
            aria-labelledby="problem-radio-buttons-group-label"
            name="problem-row-radio-buttons-group"
            onInput={(e) => setProblemValue(e.target.value)}
          >
            <FormControlLabel value="delay" control={<Radio />} label="Delayed flight" />
            <FormControlLabel value="cancelation" control={<Radio />} label="Canceled flight" />                
          </RadioGroup>
        </FormControl>   */}
          <Button style={{ minWidth: '160px', maxHeight: '56px' }}
            type="submit"
            disabled={loading}
            variant="contained">
              { loading ?  "Loading...": "Search Flight" }
            </Button>
          {/* <Button variant="contained" onClick={() => props.changeMyAppData({ShowElements: 'true', Route: {routeValue}, Date:(dateFlightValue), Problem:(problemValue), AnswertTo:(answertoValue) })}>Search Flight</Button>       */}
          
        </Stack>
        </Box>
        <Box>
        <h2>TRY TO PRE-FILL BY SOME TEST FLIGHTS</h2>
        <Stack
          spacing={1}
          padding={{ xs: '2%', sm: '0% 10% 0% 10%' }}
          maxWidth={{ xs: '99%', sm: '80%' }}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'center', sm: 'center' }}
          alignContent={{ xs: 'center', sm: 'center' }}
          textAlign={{ xs: 'center', sm: 'center' }}>        
        <Button
          variant="outlined"
          onClick={handleTestButton1Click}

        >TEST: Canceled Flight</Button>
         <Button
          variant="outlined"
          onClick={handleTestButton2Click}
        >TEST: Flight Delay</Button>

        <Button
          variant="outlined"
          onClick={handleTestButton3Click}
        >TEST: Diverted flight</Button>

        <Button
          variant="outlined"
          onClick={handleTestButton4Click}
        >TEST: OK flight</Button>

        {/* <TextField 
          id="dropzone"
          label="SALESFORCE DROP ZONE (BETA v0.1): CTRL+V selected text here and I will parse for you."
          multiline
          rows={4}          
          variant="outlined"
          fullWidth={100}  
          onChange={handleDropZoneChange}
        />          */}
       
                </Stack>      
        </Box>
        {/* <Box> 
         {myPersonalEmanuelUserData.is_owner &&<label>No Cache Usage:</label> }
         {myPersonalEmanuelUserData.is_owner &&<Checkbox id='noCacheBox' name='noCacheBox' checked={noCache} onClick={handleNoCacheChange} />  }                
         {myPersonalEmanuelUserData.is_owner &&<label>  Choose data source </label> }
         {myPersonalEmanuelUserData.is_owner &&<NativeSelect 
                defaultValue={dataSource}      
                variant="outlined"
                inputProps={{
                  name: 'dataSourceName',
                  id: 'dataSourceId',
                }}
                onChange={handleDataSourceChange}                
                style={{ minWidth: '100px' }}
                value={dataSource}
                label="  Choose data source: "
                placeholder=" Choose data source: "
               // onChange={(event) => { setDataSourceValue(event.target.value) }}
              > <option value={'flightAware'}>FlightAware</option>
              <option value={'aviationEdge'}>AviationEdge</option>
              <option value={'both'}>Both</option>              
              </NativeSelect> }                
        </Box> */}
        
      </Box>
      ) : (
        <Navigate replace to="/login" />
      )}
      <ReportMistakeDrawer danielJson={dataToVizualize} filters={null} activityCorrelationID={null}  myuser={user} dropzone={airlineValue+" "+ flightNumber+" "+fromIATA+" - "+ toIATA+" "+ dateFlightString}/>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import {supabase} from '../lib/supabase';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Typography from '@mui/material/Typography';
import { InputLabel, NativeSelect, Select } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from '../components/authContextProvider/AuthContext';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import UserEdit from '../components/userManagement/UserEdit';


export default function UserProfile() {
  
  const [emanuelUser, setEmanuelUser] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null);  
  const [userName, setUserName] = useState(emanuelUser?.full_name || null);
  const [secondaryEmail, setSecondaryEmail] = useState(emanuelUser?.secondary_email || null);
  const [phoneNumber, setPhoneNumber] = useState(emanuelUser?.phone_number || null);
  const [logoUrl, setLogoUrl] = useState('');  
  const [userEmail, setUserEmail] = useState('');  
  const { user } = useUser();

  const navigate = useNavigate(); 

   useEffect(() => {
      setUserEmail(user?.email);      
     // setEmanuelUser(JSON.parse(localStorage.getItem("emanuelUser")));
      setLogoUrl(process.env.REACT_APP_LOGO_URL);
      console.log("emanuel user: ",emanuelUser);
  }, []);

  async function logActivity (userData, activity)
  {
    console.log(userData);
    //const correlationID = uuid();
    
    const { data, error } = await supabase
      .from('logUserActivity')
      .insert([
        { user_email: userData.email, user_id: userData.id, type: activity, correlationID: null },
      ])
  }

   function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://paxhelper.com">
          paxhelper.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
   }

   const theme = createTheme();
   
    return (
      <Box>
               {user ? (
        <UserEdit emanuelUser={emanuelUser} />
         ) : (
          <Navigate replace to="/login" />
          )}
        
      </Box>
    );
};

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";

import ReportAirlinesPNR from '../ReportAirlinesPNR';
import AIResponseGUI from '../AIResponseGUI';

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {$generateHtmlFromNodes} from '@lexical/html';
import {supabase } from "../../../lib/supabase";


export default function DataReplacePlugin(props) {
  console.log('DataReplacePlugin PROPS')
  console.log(props);
  const [editor, setEditor] = useLexicalComposerContext();
  console.log("1",props.guiConfig );
  const [localGuiConfig, setLocalGuiConfig] = useState(JSON.parse(props.guiConfig)) || {};  
  //const [inputTemplateId, setInputTemplateId] = useState(props.templateId);    
  console.log("2", sessionStorage.getItem('AirLawDataEntity'));
  const [danielJson, setDanielJson] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity'))) || {}; 
  console.log("3");
  const [wgArrival, setWgArrival] = useState("https://www.wunderground.com/history/daily/"+danielJson?.airLawData?.arrival?.codeIso2Country+"/"+danielJson?.airLawData?.arrival?.nameCity+"/"+danielJson?.airLawData?.arrival?.icaoCode+"/date/"+danielJson?.airLawData?.arrival?.scheduledTime?.toString("YYYY-MM-dd")) || "";  
  const [wgDeparture, setWgDeparture] = useState("https://www.wunderground.com/history/daily/"+danielJson?.airLawData?.departure?.codeIso2Country+"/"+danielJson?.airLawData?.departure?.nameCity+"/"+danielJson?.airLawData?.departure?.icaoCode+"/date/"+danielJson?.airLawData?.departure?.scheduledTime?.toString("YYYY-MM-dd")) || "";  
  console.log("4");   
  const [metarArrival, setMetarArrival] = useState(danielJson?.arrivalAirportWeatherHistory?.nearestRaw) || "";
  const [metarDeparture, setMetarDeparture] = useState(danielJson?.departureAirportWeatherHistory?.nearestRaw) || "";
  console.log("5");  
  const [emanuelUser, setEmanuelUser] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null);  
  console.log("6");
  
  console.log("7");
  var myDelay = 0;
  if(danielJson?.airLawData?.arrival?.delay != null) myDelay = danielJson?.airLawData?.arrival?.delay + " min.";
  else if (danielJson?.airLawData?.departure?.delay != null) myDelay = danielJson?.airLawData?.departure?.delay + " min."
  

  var myContent = {
    agentName: emanuelUser.full_name,
    compensationAmount:  danielJson?.airLawData?.flightEvaluationEngine?.suggestedEU261CompensationSum,
    flightNumber: danielJson?.airLawData?.flight?.iataNumber,
    flightNumberExtended: danielJson?.airLawData?.flight?.iataNumber + " (" + danielJson?.airLawData?.departure?.iataCode + " - " + danielJson?.airLawData?.arrival?.iataCode + ") " + danielJson?.airLawData?.departure?.scheduledTime,
    wgArrivalLink: wgArrival,
    wgDepartureLink: wgDeparture,
    metarArrivalData: metarArrival,
    metarDepartureData: metarDeparture,
    departureCountryName: danielJson?.airLawData?.departure?.nameCountry,
    delayMinutes: myDelay
   };
 
  if (localGuiConfig != null) 
  {
     if(localGuiConfig.hasOwnProperty("replyToName") == false) 
     {
      console.log("IM INSIDE THE REPLACEMENT CONDITION:", localGuiConfig);     
     
      var newKey = "replyToName";
      var newVal = true;
      localGuiConfig[newKey] = newVal;
      setLocalGuiConfig(localGuiConfig);
     }
     if(localGuiConfig.hasOwnProperty("wunderGroundLink") == false) 
     {
      console.log("IM INSIDE THE REPLACEMENT CONDITION:", localGuiConfig);     
     
      var newKey = "wunderGroundLink";
      var newVal = false;
      localGuiConfig[newKey] = newVal;
      setLocalGuiConfig(localGuiConfig);
     }
     if(localGuiConfig.hasOwnProperty("departureCountryName") == false) 
     {
      console.log("IM INSIDE THE REPLACEMENT CONDITION:", localGuiConfig);     
     
      var newKey = "departureCountryName";
      var newVal = false;
      localGuiConfig[newKey] = newVal;
      setLocalGuiConfig(localGuiConfig);
     }
     if(localGuiConfig.hasOwnProperty("flightNumberExtended") == false) 
     {
      console.log("IM INSIDE THE REPLACEMENT CONDITION:", localGuiConfig);     
     
      var newKey = "flightNumberExtended";
      var newVal = false;
      localGuiConfig[newKey] = newVal;
      setLocalGuiConfig(localGuiConfig);
     }
     if(localGuiConfig.hasOwnProperty("delay") == false) 
     {
      console.log("IM INSIDE THE REPLACEMENT CONDITION:", localGuiConfig);     
     
      var newKey = "delay";
      var newVal = false;
      localGuiConfig[newKey] = newVal;
      setLocalGuiConfig(localGuiConfig);
     }
  }
  else setLocalGuiConfig(JSON.parse('{"passengerName":true,"caseId":true,"compensationAmount":true,"flightNumber":true,"flightNumberExtended":true,"departureCountryName":true,"bookingNumber":true}'));

  console.log("localGUIConfig sending:", localGuiConfig);
  console.log("myContent sending:", myContent);

  return useDataReplaceComponent(editor, setEditor,  JSON.stringify(myContent), JSON.stringify(localGuiConfig), props.user, props.templateId, props.templateLanguage);
}
// const desiredUpdate = '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"nahradene hodnoty","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}';

async function logActivity (userData, activity, myExtras)
{
//  console.log(userData);
  var activityCorrelationID = '';
  if(sessionStorage.getItem('activityCorrelationID') == null) {
    console.log('activityCorrelationID returned NULL')
    sessionStorage.setItem('activityCorrelationID', uuid())
  }
  else{
    // console.log('activityCorrelationID returned value')
    // console.log(activityCorrelationID);
    // console.log( localStorage.getItem('activityCorrelationID'));

    activityCorrelationID = sessionStorage.getItem('activityCorrelationID');
    // console.log(activityCorrelationID);
  }

  const { data, error } = await supabase
    .from('logUserActivity')
    .insert([
      { user_email: userData.email, user_id: userData.id, type: activity, correlationID: activityCorrelationID, extras: myExtras },
    ])
}

async function logCaseData (userData, caseID, contentState, originalState, templateID, language )
{
  var activityCorrelationID = '';
  if(sessionStorage.getItem('activityCorrelationID') != null) {
    activityCorrelationID = sessionStorage.getItem('activityCorrelationID');
  }

  const danielsData = sessionStorage.getItem('AirLawDataEntity');
  const { data, error } = await supabase
    .from('logCases')
    .insert([
      { caseId: caseID, user_email: userData.email, user_id: userData.id, correlationId: activityCorrelationID, templateId: templateID, templateLanguage:language, response: contentState, hardData: danielsData },
    ]) 
}

function useDataReplaceComponent(editor, setEditor, content, guiConfig, user, templateId, templateLanguage) {
  console.log('I`m useDataReplaceComponent: and this is value of guiConfig as I see this:', guiConfig);
  console.log('I`m useDataReplaceComponent: and this is value of content as I see this:', content);
  //const [contentEditorJson,setContentEditorJson] = useState(editor);

  async function onCopyTextButtonClick() {
    console.log('I`m the CopyTextButton: DO NOT FORGET I WORKS ONLY ON PRODUCTION ');
    await logActivity (user, 'clipboard', "");
    const editorState = editor.getEditorState();
    editorState.read(() => {      
      navigator.clipboard.writeText($generateHtmlFromNodes(editor, null))
    });    
  }
  

  async function onReplaceButtonClick(replyToNameValue, passengerNameValue, caseIdValue, bookingNumberValue, wgDepartureLinkValue, wgArrivalLinkValue, metarDepartureValue, metarArrivalValue, flightNumberValue, flightNumberValueExtended, departureCountryNameValue, delayValue, compensationAmountValue,agentNameValue ) {

      await logActivity (user, 'replace', "templateId: "+templateId);

      
      const replyToNameTechnicalString = '{{REPLY_TO_NAME}}';
      const passengerNameTechnicalString = '{{PASSENGER_NAME}}';
      const caseIdTechnicalString = '{{CASE_ID}}';
      const bookingNumberTechnicalString = '{{BOOKING_NO}}';
      const wunderGroundLinkDepartureTechnicalString = '{{WUNDERGROUND-WEATHER-LINK-DEPARTURE}}';
      const wunderGroundLinkArrivalTechnicalString = '{{WUNDERGROUND-WEATHER-LINK-ARRIVAL}}';
      const metarDepartureTechnicalString = '{{METAR-DEPARTURE}}';
      const metarArrivalTechnicalString = '{{METAR-ARRIVAL}}';
      const flightNumberTechnicalString = '{{FLIGHT_CODE}}';
      const flightNumberExtendedTechnicalString = '{{FLIGHT_CODE_EXTENDED}}';
      const departureCountryNameTechnicalString = '{{DEPARTURE_COUNTRY_NAME}}';
      const delayTechnicalString = '{{PUT_DELAY}}';
      const compensationAmountTechnicalString = '{{COMPENSATION_AMOUNT}}';
      const agentNameTechnicalString = '{{AGENT_NAME}}';
      

      if(!replyToNameValue) replyToNameValue = replyToNameTechnicalString;
      if(!caseIdValue) caseIdValue = caseIdTechnicalString;
      if(!bookingNumberValue) bookingNumberValue = bookingNumberTechnicalString;
      if(!wgDepartureLinkValue) wunderGroundDepartureLinkValue = wunderGroundLinkDepartureTechnicalString;
      if(!wgArrivalLinkValue) wunderGroundArrivalLinkValue = wunderGroundLinkArrivalTechnicalString;
      if(!metarDepartureValue) metarDepartureValue = metarDepartureTechnicalString;
      if(!metarArrivalValue) metarArrivalValue = metarArrivalTechnicalString;
      if(!flightNumberValue) flightNumberValue = flightNumberTechnicalString;
      if(!flightNumberValueExtended) flightNumberValueExtended = flightNumberExtendedTechnicalString;
      if(!departureCountryNameValue) departureCountryNameValue = departureCountryNameTechnicalString;
      if(!delayValue) delayValue = delayTechnicalString;
      if(!compensationAmountValue) compensationAmountValue = compensationAmountTechnicalString;      
      if(!passengerNameValue) passengerNameValue = passengerNameTechnicalString;
      if(!agentNameValue) agentNameValue = agentNameTechnicalString;
      else if(agentNameValue.trim() != "") agentNameValue = agentNameValue.toUpperCase();


      
      
      
      //console.log(editor.getRootElement()?.innerText);
      console.log(passengerNameValue);
      //console.log(JSON.stringify(desiredUpdate.editorState));
      const originalState = JSON.stringify(editor.getEditorState());
      const newState2 = originalState.replace(caseIdTechnicalString, caseIdValue);
      const newState3 = newState2.replace(bookingNumberTechnicalString, bookingNumberValue);
      const newState4 = newState3.replace(flightNumberTechnicalString, flightNumberValue);
      const newState5 = newState4.replace(flightNumberExtendedTechnicalString, flightNumberValueExtended);
      const newState6 = newState5.replace(departureCountryNameTechnicalString, departureCountryNameValue);
      const newState7 = newState6.replace(compensationAmountTechnicalString, compensationAmountValue);
      const newState8 = newState7.replace(delayTechnicalString, delayValue);
      const newState9 = newState8.replace(wunderGroundLinkDepartureTechnicalString, wgDepartureLinkValue);
      const newState10 = newState9.replace(wunderGroundLinkArrivalTechnicalString, wgArrivalLinkValue);
      const newState11 = newState10.replace(metarDepartureTechnicalString, metarDepartureValue);
      const newState12 = newState11.replace(metarArrivalTechnicalString, metarArrivalValue);
      const newState13 = newState12.replace(agentNameTechnicalString, agentNameValue);
      const newState14 = newState13.replace(replyToNameTechnicalString, replyToNameValue);
      const newState15 = newState14.replace(passengerNameTechnicalString, passengerNameValue);

      editor.setEditorState(editor.parseEditorState(newState15));  


      await logCaseData (user, caseIdValue, newState12, originalState, templateId, templateLanguage);

      return true;
  }
  
  return (
    <DataReplaceComponent onReplaceButtonClick={onReplaceButtonClick} onCopyTextButtonClick={onCopyTextButtonClick} contentEditorJson={editor} contentJson={content} guiConfig={guiConfig} templateId={templateId}/>
  );
}

function DataReplaceComponent ({onReplaceButtonClick, onCopyTextButtonClick, contentEditorJson, contentJson, guiConfig, templateId}) {
  // const initData = content;
  // const initData = {
  //   agentName: 'Oleksii Arestovych',
  //   compensationAmount: '400 €',
  //   flightNumber: 'W62375',
  //  };
 
  //TODO: FIX ME: (content error in database containing wrong name of objects in json guiConfig)
  console.log("myConfig updated:", guiConfig);
  console.log("contentJson updated:", contentJson);
  var content =  JSON.parse(contentJson);
  var myConfig = JSON.parse(guiConfig);
  
  // console.log('This is myConfig object');
  // console.log(myConfig);
  // console.log(myConfig.passengerName);
  
  //const myConfig = JSON.parse('{"CUSTOMER_NAME":true,"CASE_ID":true,"COMPENSATION_AMOUNT":true,"FLIGHT_CODE":true,"BOOKING_NO":true}');
  
  //const [contentEditorJson,setContentEditorJson] = useState(contentEditorJson);
  const [replyToNameValue, setReplyToNameValue] = useState("");
  const [passengerNameValue, setNameValue] = useState("");
  const [caseIdValue, setCaseIdValue] = useState("");
  const [bookingNumberValue, setBookingNumberValue] = useState("");
  const [wgDepartureLink, setWgDepartureLinkValue] = useState(content.wgDepartureLink) || null;
  const [wgArrivalLink, setWgArrivalLinkValue] = useState(content.wgArrivalLink) || null;
  const [flightNumberValue, setFlightNumberValue] = useState(content.flightNumber) || null;
  const [flightNumberValueExtended, setFlightNumberValueExtended] = useState(content.flightNumberExtended) || null;
  const [departureCountryNameValue, setDepartureCountryNameValue] = useState(content.departureCountryName) || null;
  const [delayValue, setDelayValue] = useState(content.delayMinutes) || null;
  const [compensationAmountValue, setCompensationAmountValue] = useState(content.compensationAmount) || null;
  const [agentNameValue, setAgentNameValue] = useState(content.agentName) || null;
  const [metarDeparture, setMetarDeparture] = useState(content.metarDepartureData) || null;
  const [metarArrival, setMetarArrival] = useState(content.metarArrivalData) || null;

  const navigate = useNavigate();  
  const handlRedirect = () => {       
    navigate('/flightsearch');
  };

  const handlParaphrase = () => {
    //TO BE IMPLEMENTED
  }

  // const handleNameChange = (event) => {    
  //   const newValue = event.target.value;
  //   console.log(newValue);
  //   setNameValue(newValue);
  // }

  return (
    <Stack spacing={1} padding={3}>
      <h2>Replacement data</h2>
      {myConfig.replyToName && <TextField        
        id="ReplyToName"
        placeholder='ReplyTo Customer/Company Name {{REPLY_TO_NAME}}'          
        value = {replyToNameValue}
        onChange={(event) => setReplyToNameValue( event.target.value )}    
      />     }
      {myConfig.passengerName && <TextField        
        id="PassngerName"
        placeholder='Passenger(`s) Name {{PASSENGER_NAME}}'          
        value = {passengerNameValue}
        onChange={(event) => setNameValue( event.target.value )}    
      />     }
      <TextField        
        id="CaseUID"
        placeholder='CASE UID {{CASE_ID}}'                        
        value = {caseIdValue}
        onChange={(event) => setCaseIdValue( event.target.value )}    
      />       
      {myConfig.bookingNumber && <TextField        
        id="BookingNumber"
        placeholder='Booking number {{BOOKING_NO}}'    
        value = {bookingNumberValue}
        onChange={(event) => setBookingNumberValue( event.target.value )}                      
      />   }
      {/* {myConfig.wunderGroundLink && <TextField        
        id="WunderGroundLink"
        placeholder='WunderGroundLink {{WUNDERGROUND-WEATHER-LINK}}'    
        value = {wunderGroundLinkValue}
        onChange={(event) => setWunderGroundLinkValue( event.target.value )}                      
      />   } */}
      {myConfig.flightNumber && <TextField        
        id="FlightNumber"
        placeholder='Flight code {{FLIGHT_CODE}}'
        value = {flightNumberValue}
        onChange={(event) => setFlightNumberValue( event.target.value )}                          
      />     }
        {myConfig.flightNumberExtended && <TextField        
        id="FlightNumberExtended"
        placeholder='Flight code {{FLIGHT_CODE_EXTENDED}}'
        value = {flightNumberValueExtended}
        onChange={(event) => setFlightNumberValueExtended( event.target.value )}                          
      />     }
      {myConfig.departureCountryName && <TextField        
        id="DepartureCountryName"
        placeholder='Departure Country {{DEPARTURE_COUNTRY_NAME}}'
        value = {departureCountryNameValue}
        onChange={(event) => setDepartureCountryNameValue( event.target.value )}                          
      />     }
      {myConfig.delay && <TextField        
        id="FlightDelay"
        placeholder='Flight delay {{PUT_DELAY}}'
        value = {delayValue}
        onChange={(event) => setDelayValue( event.target.value )}                          
      />     }
      {myConfig.compensationAmount && <TextField        
        id="CompensationAmount"
        placeholder='Compensation amount {{COMPENSATION_AMOUNT}}'                        
        value = {compensationAmountValue}
        onChange={(event) => setCompensationAmountValue( event.target.value )}    
      /> }
      <TextField        
        id="AgentName"
        placeholder='Your name {{AGENT_NAME}}'                        
        value = {agentNameValue}
        onChange={(event) => setAgentNameValue( event.target.value )}    
      />  
      
       <Box padding={1}>
        <Button variant="contained" fullWidth='true'  onClick={() => onReplaceButtonClick(replyToNameValue, passengerNameValue, caseIdValue, bookingNumberValue, wgDepartureLink, wgArrivalLink, metarDeparture, metarArrival, flightNumberValue,  flightNumberValueExtended, departureCountryNameValue, delayValue, compensationAmountValue, agentNameValue)} >Replace values</Button>      
      </Box>
      <Box padding={1}>
        <Button variant="contained" fullWidth='true' onClick={onCopyTextButtonClick}  >Copy To Clipboard</Button>
      </Box>
      <Box padding={1}>
        <ReportAirlinesPNR caseIdValue={caseIdValue} flightNumberValue={flightNumberValue} passengerNameValue={passengerNameValue} replyToNameValue={replyToNameValue} templateId={templateId} />
      </Box>
      {/* <Box padding={1} >
        <Button variant="contained" fullWidth='true' onClick={onCreateAIResponseButtonClick}  >Generate personalized AI response</Button>
      </Box> */}
      <Box padding={1}>
        <AIResponseGUI contentEditorJson={contentEditorJson} />
      </Box>
      <Box padding={1} >
        <Button variant="contained" fullWidth='true' onClick={handlRedirect} >New Flight Search</Button>
      </Box>
    </Stack>
  );
}
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import { AlignHorizontalCenter } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';


export default function ReportAirlinesPNR(props) {
  const [open, setOpen] = React.useState(false);
  const [templateId, setTemplateId] = React.useState(props.templateId);
  const navigate = useNavigate();  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("templateId inside report", templateId);    
}, []);

  return (
    <>
      <Button variant="contained" label="Airline Report PNR" onClick={handleClickOpen}  fullWidth='true'>
      Create PNR Report
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Airline PNR Report
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">    
          <Box key="introBox"
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >      
          <Avatar key="infoIconAuthenticated" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockResetRoundedIcon />
            </Avatar>
          <br />
          Airline PNR Report TEXT FROM PROS
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>OK</Button>          
        </DialogActions>
      </Dialog>
    </>
  );
}
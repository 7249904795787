import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

export default function Abaut() {


  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://paxhelper.com">
          paxhelper.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '90vh',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          PAX HELPER
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {'Customer Care Decision Support System.'}
        </Typography>
        <Typography variant="body1">beta version developed and provided by team of PAXHELPER Startup. <br />
          <br /> This software evaluates global flight data to clasify and identify disrupted flights. 
          Our ultimate goal is to provide the best possible customer service for air passengers in need. For Customer care agent, software suggest most relevant templates, allow personalization and customization of reply messages and collect statistics to improve Customer Care Center KPI`s. </Typography>
        <Typography variant="body1" textAlign={'right'}><br /> Give us feedback pls<br /> Thank you Marianna<br /> CEO PAXHELPER</Typography>
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="100vh">
          <Box style={{ padding: '0px', background: '#FFF', textAlign: 'center' }}>
            <Typography variant="body1">
              <span>
                <img src="../../images/supporters/1.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
                <img src="../../images/supporters/2.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
                <img src="../../images/supporters/3.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
                <img src="../../images/supporters/4.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
                <img src="../../images/supporters/5.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
                <img src="../../images/supporters/6.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
                <img src="../../images/supporters/7.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
                <img src="../../images/supporters/8.png" alt="paxhelper.com" height="80" style={{ margin: '18px' }} />
              </span>
            </Typography>
          </Box>
          <Copyright />
        </Container>
      </Box>
    </Box>



    // <div className="App">
    //   <h1>Airline Customer Care Decision Support System</h1>
    //   <p>beta version for Airline service companies developed and provided bypaxhelper.com</p>  
    //   <p>paxhelper.com evaluates global flight data in real time to identify disrupted flights<br /> and provide best possible customer service for air passengers in need.</p>        
    //   <div>
    //       <img src="../../images/airlawpro_logo.png" alt="paxhelper.com" height="50" style={{margin:'18px'}}/>
    //     </div>
    //   <p>Contact us:</p>
    //   <p>office@paxhelper.com<br/><a href="https://paxhelper.com">www.paxhelper.com</a></p>
    //   <p>+421-948-566549</p>      
    //   <div style={{padding:'50px', margin:'100px 0px 0px 0px', background:'#FFF'}}>
    //     <span>
    //       <img src="../../images/partners/1.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/2.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/3.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/4.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/5.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/6.webp" alt="paxhelper.com" height="80" style={{margin:'18px'}}/>
    //     </span>        
    //   </div>
    // </div>    
  );
};

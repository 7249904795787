import "./styles.css";
import "./App.css"
import Navbar from './components/navbar/navbar';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { supabase } from "./lib/supabase";

import Login from './pages/login';
import PasswordResetInfo from './pages/pwdresetinfo';
import PasswordResetForm from './pages/pwdresetform';
import PasswordResetRequest from './pages/pwdresetrequest';
import Message from "./pages/message";
import FlightSearch from './pages/flightsearch';
import FlightDetails from "./pages/flightdetails";
import About from './pages/about';
import Baggage from './pages/baggage';
import Reporting from './pages/reporting';
import Templates from "./pages/templates";
import { AuthContextProvider } from "./components/authContextProvider/AuthContext";
import Templatemanager from "./pages/templatemanager";
import UserProfile from "./pages/userprofile";
import UserManagement from "./pages/usermanagement";
import MistakeManagement from "./pages/mistakemanagement";
import CurrencyConvert from "./pages/currencyconvert";
import Signup from "./pages/signup";
import Search2 from "./pages/search2";


export default function App() {  
  return (    
      <AuthContextProvider>
        <Router>      
          <Navbar />      
          <Routes>
              <Route path='/' element={<Login/>} />             
              <Route path='/login' element={<Login/>} />             
              <Route path='/signup' element={<Signup/>} />             
              <Route exact path='/flightsearch' element={<FlightSearch />} />
              <Route exact path='/search2' element={<Search2 />} />
              <Route path='/flightdetails' element={<FlightDetails />} />
              <Route path='/templates' element={<Templates />} />             
              <Route path='/about' element={<About/>} />             
              <Route path='/currencyconvert' element={<CurrencyConvert />} />
              <Route path='/pwdresetform' element={<PasswordResetForm/>} />             
              <Route path='/pwdresetrequest' element={<PasswordResetRequest/>} />       
              <Route path='/pwdresetinfo' element={<PasswordResetInfo/>} />                   
              <Route path="/message" element={<Message/>} />
              <Route path="/templatemanager" element={<Templatemanager/>} />
              <Route path='/reporting' element={<Reporting/>} />   
              <Route path='/userprofile' element={<UserProfile/>} />   
              {/* <Route path='/newuser' element={<UserNew/>} />    */}
              <Route path='/usermanagement' element={<UserManagement/>} />   
              <Route path='/mistakemanagement' element={<MistakeManagement/>} />
                 
              
              {/* <Route path='/baggage' element={<Baggage/>} />    */}
              
          </Routes>  
        </Router>            
      </AuthContextProvider>    
  );
}
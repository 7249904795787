import React, {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import {supabase } from "../../lib/supabase";
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';



export default function AccordeonItem(props) {

  //const defaultPlaceholders = "{\“CUSTOMER_NAME\”:true,\"CASE_ID\”:false,\"COMPENSATION_AMOUNT\”:false,\"FLIGHT_CODE\”:false,\"FLIGHT_CODE_EXTENDED\”:false,\“AGENT_NAME\”:true,\“COUNTRY_NAME\”:false,\“BOOKING_NO\”:false,\“PASSENGER_NAME\”:false}";
  // console.log("accorden props:");
  // console.log(props);
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const [templatesLanguages, setTemplatesLanguages] = useState([]);
  const [userToLog, setUserToLog] = useState(props.user || null);  
//   const [longDescription, setLongDescription] = React.useState('Instructions how to use this template');
//   const [shortDescription, setShortDescription] = React.useState('Short description');
//   const [templateName, setTemplateName] = React.useState('Template xxx');
//   const [templateNumber, setTemplateNumber] = React.useState('1');
//   const [templateLanguages, setEmanuelTemplateLanguages] = React.useState('EN,IT,PL');
//   const [emanuelTemplateId, setEmanuelTemplateId] = React.useState('1');

  async function logActivity (userData, activity)
  {
  //  console.log(userData);
    var activityCorrelationID = '';
    if(sessionStorage.getItem('activityCorrelationID') == null) {
      //console.log('activityCorrelationID returned NULL')
      sessionStorage.setItem('activityCorrelationID', uuid())
    }
    else{
      // console.log('activityCorrelationID returned value')
      // console.log(activityCorrelationID);
      // console.log( localStorage.getItem('activityCorrelationID'));

      activityCorrelationID = sessionStorage.getItem('activityCorrelationID');
      // console.log(activityCorrelationID);
    }

    const { data, error } = await supabase
      .from('logUserActivity')
      .insert([
        { user_email: userData.email, user_id: userData.id, type: activity, correlationID: activityCorrelationID },
      ])
  }

  const handleTemplateClick = async (templateLanguage,selectedTemplateId,templateName,placeholders) => {    
    //console.log('handleTemplateClick user value:')
    //console.log(userToLog)
    await logActivity (userToLog, 'template');
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$$$$$$$$$$$$$$$$$$$$$$ TOTO TREBA:',templateLanguage );
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$$$$$$$$$$$$$$$$$$$$$$ TOTO TREBA:',selectedTemplateId );
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$$$$$$$$$$$$$$$$$$$$$$ TOTO TREBA:',templateName );
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$$$$$$$$$$$$$$$$$$$$$$ TOTO TREBA:',placeholders );

    sessionStorage.setItem('templatePlaceholders', placeholders);
    sessionStorage.setItem('templateName', templateName);
    sessionStorage.setItem('templateNumber', props.templateNumber);
    sessionStorage.setItem('templateShortDescription', props.shortDescription);
    var value = props.longDescription;
    if((!value || value == undefined || value == "" || value.length == 0)) sessionStorage.setItem('templateLongDescription', "");
    else sessionStorage.setItem('templateLongDescription', props.longDescription);
    sessionStorage.setItem('templateCountriesLimitation', props.countriesLimitations);

    //console.log("this is english button function ");
    await fetchData(templateLanguage,selectedTemplateId, templateName);
    
    if(props.editorView)
    { 
      //console.log("THIS IS IS MANAGER VALUE ");
      //console.log(props.isManager);

      prepTagOKorOtherFiltersJson(selectedTemplateId);
      prepTagDelayFiltersJson(selectedTemplateId);
      prepResponseOrderFiltersJson(selectedTemplateId);
      prepAirlinesFiltersJson(selectedTemplateId);
      prepTagCanceledFiltersJson(selectedTemplateId);
      navigate('/templatemanager?templateId='+selectedTemplateId+'&lang='+templateLanguage);
    }
    else
    {
      //console.log("THIS IS IS MANAGER VALUE ");
      //console.log(props.isManager);
      navigate('/message?templateId='+selectedTemplateId+'&lang='+templateLanguage);
    }
    
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const prepLanguageData = async () => {        
    const { data, error } = await supabase.from('languages').select();
    if (error) {
      console.log("I`m the prepLanguageData Function returning error:")
      console.log(error);
      throw error;
    }
    else {
      //console.log("I`m the prepLanguageData Function returning data:")
      //console.log(data);
      setTemplatesLanguages(data);  
    }     
  };

  const fetchData = async (templateLanguage, templateId) => {      
    const {data, error} = await supabase.from('templates').select(templateLanguage, ).eq('id', templateId);
    if (error) {
      console.log("I`m the EDITOR getSupabaseTemplate Function returning error:")
      console.log(error);  
      sessionStorage.setItem('messageEditor', '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"toto je uplne namakany template 3 zo stringu","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}');
    }
    else {
      //DEBUG DATA TO VALIDATE RETURNED CONTENT
   //   console.log("I`m the EDITOR getSupabaseTemplate Function returning data:")          
      if(templateLanguage == 'EN')
      sessionStorage.setItem('messageEditor', data[0].EN);//          getTemplate(templateId, templateLanguage);      
      if(templateLanguage == 'LV')
      sessionStorage.setItem('messageEditor', data[0].LV);//          getTemplate(templateId, templateLanguage);       
    }     
  };


  const [responseOrderFilters, setResponseOrderFilters] = useState({
    '1st': false,
    '2nd': false,
    '3rd': false,
    '4th': false,
    '5th': false
  });

  const [airlineFilters, setAirlineFilters] =  useState({
    'BT': false        
  });     

  const [tagCanceledFilters, setTagCanceledFilters] = useState({
    'covid': false,
    'airportStrike': false,
    'notam': false,
    'weather': false,
    '2years': false,
    '14days': false,    
    'payCompensation': false    
  }); 

  const [tagDelayFilters, setTagDelayFilters] = useState({
    'covid': false,
    'airportStrike': false,
    'notam': false,
    'weather': false,
    '2years': false,
    'delayLessThan3hours': false,
    '3hours': false,
    '5hours': false,
    'payCompensation': false    
  }); 

  const [tagOKorOtherFilters, setTagOKorOtherFilters] = useState({
    'covid': false,
    'airportStrike': false,
    'notam': false,
    'weather': false,
    '2years': false,    
    'diverted': false,    
    'showAllways': false
  }); 


  const prepResponseOrderFiltersJson = async (templateId) => {
    //in a future dynamic loading of all filters + their icons from database      
    //console.log('This is prep function of TmeplateManaer. I`m working with templateid:', templateId);
    
    const { data, error } = await supabase.from('responseOrders-templates').select('responseOrderName').eq('template', templateId);
    if (error) {
      console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
      //console.log("I`m the getSupabaseTemplates Function returning data:")
      //console.log(data);  
      //console.log(responseOrderFilters);     
      const result = convertArrayToJSON(data, responseOrderFilters, 'order');
      //console.log(result); 
      sessionStorage.setItem('responseOrderFilters', JSON.stringify(result));      
    }
  };

  const prepAirlinesFiltersJson = async (templateId) => {
    //in a future dynamic loading of all filters + their icons from database      
    //console.log('This is prep function of prepAirlinesFiltersJson. I`m working with templateid:', templateId);
    
    const { data, error } = await supabase.from('airlines-templates').select('airline').eq('template', templateId);
    if (error) {
      console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
      //console.log("I`m the prepAirlinesFiltersJson Function returning data:")
      //console.log(data);  
      //console.log(airlineFilters);     
      const result = convertArrayToJSON(data, airlineFilters, 'airlines');
      sessionStorage.setItem('airlineFilters', JSON.stringify(result));      
    }
  };

  const prepTagCanceledFiltersJson = async (templateId) => {
    //in a future dynamic loading of all filters + their icons from database      
    //console.log('This is prep function of prepTagCanceledFiltersJson. I`m working with templateid:', templateId);
    
    const { data, error } = await supabase.from('tags').select('tagName').ilike('tagFlightStatus', '%canceled%').eq('template', templateId);
    if (error) {
      console.log("I`m the prepTagCanceledFiltersJson (with categoryFilter) Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
      //console.log("I`m the prepTagCanceledFiltersJson Function returning data:")
      //console.log(data);  
      //console.log(tagCanceledFilters);     
      const result = convertArrayToJSON(data, tagCanceledFilters, 'tags');
      //console.log(result); 
      sessionStorage.setItem('tagCanceledFilters', JSON.stringify(result));      
    }
  };

  const prepTagDelayFiltersJson = async (templateId) => {
    //in a future dynamic loading of all filters + their icons from database      
    console.log('This is prep function of prepTagDelayFiltersJson. I`m working with templateid:', templateId);
    
    const { data, error } = await supabase.from('tags').select('tagName').ilike('tagFlightStatus', '%delay%').eq('template', templateId);
    if (error) {
      console.log("I`m the prepTagDelayFiltersJson (with categoryFilter) Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
      //console.log("I`m the prepTagDelayFiltersJson Function returning data:")
      //console.log(data);  
      //console.log(tagDelayFilters);     
      const result = convertArrayToJSON(data, tagDelayFilters, 'tags');
      //console.log(result); 
      sessionStorage.setItem('tagDelayFilters', JSON.stringify(result));      
    }
  };

  const prepTagOKorOtherFiltersJson = async (templateId) => {
    //in a future dynamic loading of all filters + their icons from database      
    console.log('This is prep function of prepTagOKorOtherFiltersJson. I`m working with templateid:', templateId);
    
    const { data, error } = await supabase.from('tags').select('tagName').ilike('tagFlightStatus', '%other%').eq('template', templateId);
    if (error) {
      console.log("I`m the prepTagOKorOtherFiltersJson (with categoryFilter) Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
     // console.log("I`m the prepTagOKorOtherFiltersJson Function returning data:")
      //console.log(data);  
      //console.log(tagOKorOtherFilters);     
      const result = convertArrayToJSON(data, tagOKorOtherFilters, 'tags');
     // console.log(result); 
     sessionStorage.setItem('tagOtherFilters', JSON.stringify(result));      
    }
  };



  function convertArrayToJSON(array, comparisonObj, type) {
    let result = {};
  
    // Loop through the comparison object and set all values to false
    for (let key in comparisonObj) {
      result[key] = false;
    }
    if(type == 'tags')
    {
      array.forEach((item) => {
        if (result.hasOwnProperty(item.tagName)) {
          result[item.tagName] = true;
        }
      });
    }

    if(type == 'order')
    {
        // Loop through the array of objects and set values to true if the key is present
      array.forEach((item) => {
        if (result.hasOwnProperty(item.responseOrderName)) {
          result[item.responseOrderName] = true;
        }
      });
    }
    else {
      array.forEach((item) => {
        if (result.hasOwnProperty(item.airline)) {
          result[item.airline] = true;
        }
      });
    }

  
    // Return the converted JSON object
    return result;
  }

  useEffect(() => {
        prepLanguageData();
    }, []);  
   

  return (
    <div>       
        <Accordion key={props.emanuelTemplateId} expanded={expanded === props.emanuelTemplateId} onChange={handleChange(props.emanuelTemplateId)}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={props.emanuelTemplateId+"bh-content"}
            id={props.emanuelTemplateId+"bh-header"}
            >
            <Typography sx={{ width: { xs: '70%', sm: '25%' } }} >
            {props.templateNumber +". "+props.templateName}
            </Typography>
            <Typography sx={{ width: { xs: '0%', sm: '50%' }, display: { xs: 'none', sm: 'block' }, color: 'text.secondary'}} >{props.shortDescription}</Typography>            
            <Typography sx={{ width: { xs: '30%', sm: '25%' } }}>
                {templatesLanguages!=null && templatesLanguages.map((myLanguage)=> (
                    <Button sx={{ width: 40, margin: '1px' }} key={props.emanuelTemplateId+myLanguage.languageCode} variant="contained" onClick={() => handleTemplateClick(myLanguage.languageCode, props.emanuelTemplateId, props.templateName, props.placeholders)}>{myLanguage.languageCode}</Button>
                ))}
                 {/* <Button sx={{ width: 40, margin: '1px' }}  variant="contained"  onClick={() => handleTemplateClick('EN', props.emanuelTemplateId)}>EN</Button> 
                 <Button sx={{ width: 40, margin: '1px' }}    variant="contained"  onClick={() => handleTemplateClick('IT', props.emanuelTemplateId)}>IT</Button> 
                 <Button sx={{ width: 40, margin: '1px' }}   variant="contained"  onClick={() => handleTemplateClick('PL', props.emanuelTemplateId)}>PL</Button> */}
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                 {props.longDescription}          
            </Typography>
            </AccordionDetails>
        </Accordion>
        {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            >
            <Typography sx={{ width: '20%', flexShrink: 0 }}>
                13. Compensation B
            </Typography>          
            <Typography sx={{  width: '60%', color: 'text.secondary' }}>We pay compensation with 50 euro fee</Typography>
            <Typography sx={{ width: '20%', flexShrink: 0, }}><Button flexGrow={1}  variant="contained" spacing={1} onClick={() =>handleTemplateClick( 'en', '13')}>EN</Button> <Button flexGrow={1} variant="contained" spacing={1} onClick={() =>handleTemplateClick( 'it', '13')}>IT</Button> <Button flexGrow={1} variant="contained" onClick={() =>handleTemplateClick( 'pl', '13')}>PL</Button></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim          
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim          
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim                      
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            >
            <Typography sx={{ width: '20%', flexShrink: 0 }}>
                14. Inputs missing
            </Typography>         
            <Typography sx={{  width: '60%', color: 'text.secondary' }}>We will pay compensation, but we need more data from customer.</Typography>
            <Typography sx={{ width: '20%', flexShrink: 0, }}><Button flexGrow={1}  variant="contained" spacing={1} onClick={() =>handleTemplateClick( 'en', '14')}>EN</Button> <Button flexGrow={1} variant="contained" spacing={1} onClick={() =>handleTemplateClick( 'it', '14')}>IT</Button> <Button flexGrow={1} variant="contained" onClick={() =>handleTemplateClick( 'pl', '14')}>PL</Button></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim  
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim          
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim          
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim                    
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            >
            <Typography sx={{ width: '20%', flexShrink: 0 }}>
                22. Universal anwser template</Typography>
            <Typography sx={{  width: '60%', color: 'text.secondary' }}>Some short description here.</Typography>
            <Typography sx={{ width: '20%', flexShrink: 0, }}><Button flexGrow={1}  variant="contained" spacing={1} onClick={() =>handleTemplateClick( 'en', '22')}>EN</Button> <Button flexGrow={1} variant="contained" spacing={1} onClick={() =>handleTemplateClick( 'it', '22')}>IT</Button> <Button flexGrow={1} variant="contained" onClick={() =>handleTemplateClick( 'pl', '22')}>PL</Button></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim          
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim          
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim     
                Long instruction to the template, how to us it, when to use it what to do next.
                You can say here the rules when this teplate should be useed and what is it`s main aim            
            </Typography>            
            </AccordionDetails>
        </Accordion> */}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import { AlignHorizontalCenter } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from '@chatscope/chat-ui-kit-react';
import { TextField } from '@mui/material';


export default function AIResponseGUI(props) {


  const API_KEY = "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";
  const [open, setOpen] = React.useState(false);
  const [contentEditorJson, setContentEditorJson] = React.useState(JSON.stringify(props.contentEditorJson));
  const navigate = useNavigate();  
  const [isTyping, setIsTyping] = useState(false);
  const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
    "role": "system", 
    "content": "Get content from json structure and then paraphrase official formal airline response text for air passenger based on template: " + contentEditorJson
  }

  const [messages, setMessages] = useState([
    {
      message: "Enter PAX claim textation, please",
      sentTime: "just now",
      sender: "ChatGPT"
    }
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user"
    };
    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setIsTyping(true);

    await processMessageToChatGPT(newMessages);
  };


  async function processMessageToChatGPT(chatMessages) { 
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message}
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,  // The system message DEFINES the logic of our chatGPT
        ...apiMessages // The messages from our chat with ChatGPT
      ]
    }

    await fetch("https://api.openai.com/v1/chat/completions", 
    {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    }).then((data) => {
      return data.json();
    }).then((data) => {
      console.log(data);
      setMessages([...chatMessages, {
        message: data.choices[0].message.content,
        sender: "ChatGPT"
      }]);
      setIsTyping(false);
    });
  }

  const handleGenerativeAI = () => {
    console.log("content:", systemMessage);    
  };

  useEffect(() => {
    console.log("content:", systemMessage);    
}, []);

  return (
    <>
      <Button variant="contained" label="Airline Report PNR" onClick={handleClickOpen}  fullWidth='true'>
      AI Personalized response
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="true"
      >
        <DialogTitle id="alert-dialog-title">
          Paxhelper AI: Personalize response
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">    
          <Box key="introBox"
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >      
          <Avatar key="infoIconAuthenticated" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <SmartToyRoundedIcon />
            </Avatar>
          <br />
            <div style={{ position: "relative", height: "100%", width: "100%"}}>
              <MainContainer>
                <ChatContainer>
                  <MessageList 
                    scrollBehavior='smooth'
                    typingIndicator={isTyping ? <TypingIndicator content="Paxhelper AI is typing - it takes time"/> : null}
                  >
                  {messages.map((message, i) => {
                                  console.log(message)
                                  return <Message key={i} model={message} />
                                })}
                  </MessageList>
                  <MessageInput placeholder="Type your instruction here" onSend={handleSend} />
                </ChatContainer>
              </MainContainer>
            </div>


            {/* <h3>Enter the customer request textation:</h3>
            <TextField
          id="outlined-multiline-static"
          label="Text of Customer request"
          multiline
          rows={4}
          defaultValue=""
          fullWidth='true' 
        />
        <TextField
          id="outlined-multiline-static"
          label="Additional instructions"
          multiline
          rows={2}
          defaultValue=""
          fullWidth='true' 
        />

            <Button variant="contained" fullWidth='true' onClick={handleGenerativeAI}>Generate personalized AI response</Button> */}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>          
        </DialogActions>
      </Dialog>
    </>
  );
}
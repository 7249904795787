import React from "react";
import AirLawTable from "../components/table/AirLawTable"
import Box from '@mui/material/Box';
import { useUser } from "../components/authContextProvider/AuthContext";
import { Navigate } from "react-router-dom";
import CurrencyConverter from "../components/currency/CurrencyConverter";
import CurrencyConvertDrawer from "../components/currency/CurrencyConvertDrawer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const rows = [
  createData('USD - US dollar', 1.0909, "2023-12-01 8:43:00" ),
  createData('SEK - Sweedish crown', 11.4239, "2023-12-01 8:43:00"),
  createData('CZK - Czech crown', 24.2983,  "2023-12-01 8:43:00"),
  createData('GBP - British pound', 0.8630,  "2023-12-01 8:43:00"),
  createData('NOK - Norway crown', 11.7262, "2023-12-01 8:43:00"),
];

function createData(
  name,
  rate,
  date
) {
  return { name, rate, date };
} 


export default function CurrencyConvert() {

  const { user } = useUser();


  
  
  return (
    <div className="App">
      { user ? (<Box>
          <h1>Convert Currency</h1>
          <p>beta version for Currency converter</p>  

          <h1>IN PRODUCTION OWN CURRENCY RATES ARE ENABLED INDIVIDUALLY PER CLIENT</h1>
          <h2><strong>Default currency:</strong>EUR</h2>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Currency Name</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">DateTime</TableCell>                  
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.rate}</TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ width: '95%', typography: 'body1', margin: '30px' }}>
            <CurrencyConvertDrawer />
          </Box>
      </Box>) : (
        <Navigate replace to="/login" />
      )}
    </div>
  );
};

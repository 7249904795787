import React, { useEffect, useState } from 'react';
import {supabase} from '../../lib/supabase';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Typography from '@mui/material/Typography';
import { InputLabel, NativeSelect, Select } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from '../authContextProvider/AuthContext';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import Alert from '@mui/material/Alert';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';




export default function OrderNow(props) {
  
  const [templateId,setTemplateId] = React.useState('');
  const [salesforceId,setSalesforceId] = React.useState('');
  const [userReport, setReport] = React.useState('');
  const [userCategory, setCategory] = React.useState('99');
 
  const [resultOfUpdateVisualize, setResultOfUpdateVisualize] = useState(false);  
  const [myPersonalEmanuelUserData, setMyPersonalEmanuelUserData] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null); 
  const [isPrivate, setIsPrivate] = useState(myPersonalEmanuelUserData.is_owner);  
  const { user } = useUser();

  const navigate = useNavigate(); 


  useEffect(() => {
    setTimeout(() => {
      setResultOfUpdateVisualize(false);    
  }, 10000);
    
}, [resultOfUpdateVisualize]);

   function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://paxhelper.com">
        paxhelper.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
   }
   
   const theme = createTheme();   

   const onChangeCategory = (event) => {
    setCategory(event.target.value)
   }

   const onChangeReport = (event) => {
    setReport(event.target.value)
   }
   const handlePrivateChange = () => { 
        setIsPrivate(!isPrivate);     
  }; 

   const onChangeSalesforceId = (event) => {
    setSalesforceId(event.target.value)
   }

   async function handleSubmit (event) {
      try{
        event.preventDefault();
        console.log('userCategory', userCategory);
        console.log('userReport', userReport);
        console.log('salesforceId', salesforceId);
        console.log('props.user.email', props.myuser.email);
        console.log('props.user.id', props.myuser.id);
        console.log('props.correlationID', props.activityCorrelationID);
        console.log('props.dropzone', props.dropzone);
        const form_data = new FormData(event.currentTarget);

        const { data, error } = await supabase
        .from('logMistake')
        .insert([
          { user_email: props.myuser.email, user_id: props.myuser.id, user_findings: userReport, correlation_id: "", user_selected_category: userCategory, daniel_json: "", template_filter: "", dropzone: "", salesforce_id: salesforceId, is_private: false },
        ])
        
        if (error)
        {  
          console.log(error);
           throw error;          
        }
        console.log(data);
        if(data != null)
        {
          setResultOfUpdateVisualize(true);
        }
        
      }
      catch (error) {
                 console.log("You may be interested:");
                 console.log(error);
             }
    };  

    return (
      <ThemeProvider theme={theme}>
      <Container key="introContainer" component="main" maxWidth="xs">
        <CssBaseline />
        <Box key="introBox"
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


            <Avatar key="infoIconReportMistake" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <ListAltRoundedIcon />
            </Avatar>
            <Box key="formbox" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>           
            {(props.closeDrawer == null) && (resultOfUpdateVisualize) && <Alert severity="success">Reported successfully!</Alert> }
            {/* <h1>You found mistake? </h1>
            <p>Thank you for letting us know. Fixing flights help your colleagues get more precise information</p> */}
            <h1>LET`S TALK</h1>
            <p>Please drop us message to receive FREE trial for your organisation. Our team will contact you.</p>
            <TextField
              margin="normal"
              fullWidth
              key="salesforcetextfield"
              id="salesforceId"
              label="Your preffered contact detail"              
              name="salesforce"
              autoComplete="salesforce"                  
              onChange={onChangeSalesforceId}
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              key="usernametextfield"
              id="standard-multiline-static"
              label="Your request message"
              multiline
              name="username"
              autoComplete="username" 
              rows={4}          
              onChange={onChangeReport}
              autoFocus
            />

            <label id="label1">Your organisation is:  </label>
            <NativeSelect                            
              defaultValue={userCategory}          
              inputProps={{
                name: 'mistakes',
                id: 'uncontrolled-native',
              }}
              onChange={onChangeCategory}
              fullWidth={100}
              >
              <option value={'all_ok'}>Outsourcing call center</option>
              <option value={'categorization'}>Airline</option>
              <option value={'compensation'}>Claim compensation company</option>
              <option value={'jurisdiction'}>Dispute authority</option>          
              <option value={'departure_airport'}>Civil aviation authority</option>
              <option value={'other'}>Other</option>
            </NativeSelect>
            {/* {myPersonalEmanuelUserData.is_owner && <label>Log in private mode:</label>} */}
            {/* {myPersonalEmanuelUserData.is_owner && <Checkbox id='isPrivate' name='isPrivate' checked={isPrivate} onClick={handlePrivateChange} /> }                                 */}
            {(props.closeDrawer != null) &&<Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={props.closeDrawer(false)}
            >
              Submit
            </Button>}
            {(props.closeDrawer == null) &&<Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}              
            >
              Report
            </Button>}
            <Button onClick={props.closeDrawer(false)}>Close</Button>         
          </Box>           
        </Box>
        <Copyright key="copyrightBlock" sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    );
};
